// External imports
import React, { useState } from "react";
import {
  AppBar,
  Toolbar,
  Box,
  ToggleButton,
  Container,
  useTheme,
  Stack,
  Avatar,
  Typography,
  Tabs,
  Tab,
  Grid,
  useMediaQuery,
  Button,
  Popover,
  Divider,
  List,
  ListItemAvatar,
  ListItemText,
  ListItemButton,
  DialogContent,
  IconButton,
  Dialog,
  ButtonBase,
  ListItem,
} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import CloseIcon from "@mui/icons-material/Close";
import MovieRoundedIcon from "@mui/icons-material/MovieRounded";
import MenuBookRoundedIcon from "@mui/icons-material/MenuBookRounded";
import LiveTvRoundedIcon from "@mui/icons-material/LiveTvRounded";
import PodcastsRoundedIcon from "@mui/icons-material/PodcastsRounded";
import PeopleIcon from "@mui/icons-material/People";
import CheckIcon from "@mui/icons-material/Check";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import PersonRemoveIcon from "@mui/icons-material/PersonRemove";
import PersonAddIcon from "@mui/icons-material/PersonAdd";
import IosShareIcon from "@mui/icons-material/IosShare";
import { useMutation, useQueryClient } from "@tanstack/react-query";

// Internal imports
import HideOnScroll from "../../components/HideOnScroll.js";
import { getStatusOptions } from "../../utils/utils.js";
import useApi from "../../hooks/useApi.js";
import { toast } from "react-toastify";
import ListMenu from "../../components/ListMenu.js";
import { Link, useNavigate } from "react-router-dom";
import { useAuth } from "../../context/authContext.js";
import { ConditionalWrapper } from "../../components/ConditionalWraper.js";
import UserSearch from "../explore/UserSearch.js";
import { updateFollowStatusInCache } from "../../utils/cacheUtils.js";

const ProfileHeader = ({
  toggleDrawer,
  activeTab,
  handleTabChange,
  profile,
  isOwnProfile,
  drawerWidth,
}) => {
  // bio moves to avatar popover component below lg
  // follower/following stats move to avatar popover below md
  const { user } = useAuth();
  const theme = useTheme();
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const { followUser, unfollowUser } = useApi();
  const [bioAnchorEl, setBioAnchorEl] = useState(null);
  const [menuAnchorEl, setMenuAnchorEl] = useState(null);
  const openBio = Boolean(bioAnchorEl);
  const statusOptions = getStatusOptions(theme);
  const isBelowMd = useMediaQuery(theme.breakpoints.down("md"));
  const isBelowLg = useMediaQuery(theme.breakpoints.down("lg"));
  const isBelowSm = useMediaQuery(theme.breakpoints.down("sm"));
  const isFollowing = profile.isFollowing || false;
  const followerCount = profile.followers ? profile.followers.length : 0;
  const followingCount = profile.following ? profile.following.length : 0;
  const popoverId = openBio ? "profile-popover" : undefined;
  const [openNetworkDialog, setOpenNetworkDialog] = useState(false);
  const [activeNetworkTab, setActiveNetworkTab] = useState("follower");

  const followMutation = useMutation({
    mutationFn: followUser,
    onSuccess: (_, username) => {
      updateFollowStatusInCache({ queryClient, username, followStatus: true });
    },
    onError: (error) => {
      toast.error(error.response?.data?.message);
    },
  });

  const unfollowMutation = useMutation({
    mutationFn: unfollowUser,
    onSuccess: (_, username) => {
      updateFollowStatusInCache({ queryClient, username, followStatus: false });
    },
    onError: (error) => {
      toast.error(error.response?.data?.message);
    },
  });

  const handleBioOpen = (event) => {
    setBioAnchorEl(event.currentTarget);
  };

  const handleBioClose = () => {
    setBioAnchorEl(null);
  };

  const handleOpenNetworkDialog = (tabName) => {
    setActiveNetworkTab(tabName);
    setOpenNetworkDialog(true);
  };

  const handleCloseNetworkDialog = () => {
    setOpenNetworkDialog(false);
  };

  const handleNetworkTabChange = (event, newValue) => {
    setActiveNetworkTab(newValue);
  };

  const handleDialogSelect = (username) => {
    handleCloseNetworkDialog();
    navigate(`/explore/user/${username}`);
  };

  const handleMenuSelect = (value) => () => {
    if (value === "unfollow") {
      unfollowMutation.mutate(profile.username);
    }
    if (value === "share") handleShare();
    setMenuAnchorEl(null);
  };

  const handleShare = () => {
    navigator.clipboard
      .writeText(`https://curateapp.io/explore/user/${profile.username}`)
      .then(() => {
        toast.success("Profile URL has been copied to the clipboard!");
      })
      .catch((error) => {
        toast.error("Failed to copy profile URL. Please try again later");
      });
  };

  const menuOptions = [
    {
      label: "Share",
      value: "share",
      icon: <IosShareIcon />,
    },
    {
      label: "Unfollow",
      value: "unfollow",
      icon: <PersonRemoveIcon />,
    },
  ];

  const tabData = [
    {
      label: "Movies",
      value: "movie",
      icon: <MovieRoundedIcon />,
      count: profile.libraryCounts.movie?.total || "--",
    },
    {
      label: "Books",
      value: "book",
      icon: <MenuBookRoundedIcon />,
      count: profile.libraryCounts.book?.total || "--",
    },
    {
      label: "Podcasts",
      value: "podcast",
      icon: <PodcastsRoundedIcon />,
      count: profile.libraryCounts.podcast?.total || "--",
    },
    {
      label: "TV",
      value: "TV",
      icon: <LiveTvRoundedIcon />,
      count: profile.libraryCounts.tv?.total || "--",
    },
  ];

  return (
    <>
      <Toolbar sx={{ height: { xs: "250px", md: "200px" } }} />
      {/* The above toolbar acts as a spacer, whatever padding is applied to the below appbar
      needs to be applied as margin to the above spacer, added 0.5 to give space to show librarycard hover scale effects */}
      <HideOnScroll>
        <AppBar
          color="inherit"
          sx={{
            boxShadow: "none",
            // backgroundColor: "white",
            width: { md: `calc(100% - ${drawerWidth}px )` },
            ml: { md: `${drawerWidth}px` },
            pt: 3,
            pb: 3,
            overflowX: "auto",
            backgroundImage: "none",
          }}
        >
          <Container maxWidth="lg">
            <Toolbar disableGutters>
              <Grid container>
                <Grid
                  item
                  xs={12}
                  md={8}
                  sx={{
                    borderBottom: { xs: 0, md: 1 },
                    borderColor: { xs: undefined, md: "divider" },
                    height: "110px", // 90px of avatar + 20px gap between avatar and divider
                    display: "flex",
                    flexDirection: "row",
                  }}
                >
                  {toggleDrawer && (
                    <ToggleButton
                      // This is the menu button which only displays when the sidebar is hidden
                      aria-label="open drawer"
                      edge="start"
                      onClick={toggleDrawer}
                      size="small"
                      value="menu"
                      sx={{
                        mr: 2,
                        display: { md: "none" },
                        alignSelf: "center",
                        "&.MuiButtonBase-root.MuiToggleButton-root": {
                          border: "none",
                          borderRadius: 1,
                        },
                      }}
                    >
                      <MenuIcon />
                    </ToggleButton>
                  )}

                  <Stack
                    direction="row"
                    spacing={2}
                    alignItems="center"
                    flexGrow={1}
                  >
                    <ConditionalWrapper
                      condition={isBelowLg}
                      wrapper={(children) => (
                        <ButtonBase
                          onClick={handleBioOpen}
                          sx={{ borderRadius: "50%" }}
                        >
                          {children}
                        </ButtonBase>
                      )}
                    >
                      <Avatar
                        aria-describedby={popoverId}
                        alt={profile.givenName}
                        src={profile.picture}
                        loading="lazy"
                        sx={{
                          width: 90,
                          height: 90,
                          bgcolor: "tertiary.main",
                          "&:hover": {
                            bgcolor: "tertiary.main",
                          },
                        }}
                      />
                    </ConditionalWrapper>
                    <Stack direction="column" spacing={0.5}>
                      <Stack
                        direction={isBelowSm ? "column" : "row"}
                        spacing={isBelowSm ? 1 : 2}
                        alignItems={isBelowSm ? "flex-start" : "center"}
                      >
                        {" "}
                        <Typography>
                          <b>{profile.givenName}</b> <i>@{profile.username}</i>
                        </Typography>
                        {!user ? (
                          <Button
                            component={Link}
                            variant="contained"
                            size="small"
                            to="/?redirect=false"
                          >
                            Log in or sign up to follow
                          </Button>
                        ) : isOwnProfile ? (
                          <Button
                            variant="outlined"
                            size="small"
                            onClick={handleShare}
                            startIcon={<IosShareIcon />}
                          >
                            Share
                          </Button>
                        ) : isFollowing ? (
                          <Button
                            variant="outlined"
                            size="small"
                            onClick={(event) =>
                              setMenuAnchorEl(event.currentTarget)
                            }
                            startIcon={<CheckIcon />}
                            endIcon={<ExpandMoreIcon />}
                            disabled={unfollowMutation.isPending}
                          >
                            Following
                          </Button>
                        ) : (
                          <Button
                            variant="contained"
                            size="small"
                            onClick={() =>
                              followMutation.mutate(profile.username)
                            }
                            disabled={followMutation.isPending}
                            startIcon={<PersonAddIcon />}
                          >
                            Follow
                          </Button>
                        )}
                      </Stack>

                      <Typography
                        sx={{
                          textWrap: "balance",
                          display: { xs: "none", lg: "block" },
                        }}
                      >
                        {profile.bio}
                      </Typography>
                    </Stack>
                  </Stack>
                  <Popover
                    id={popoverId}
                    open={openBio}
                    anchorEl={bioAnchorEl}
                    onClose={handleBioClose}
                    anchorOrigin={{
                      vertical: "bottom",
                      horizontal: "left",
                    }}
                    sx={{ mt: 1 }}
                  >
                    <Box sx={{ p: 2, maxWidth: "300px" }}>
                      {isBelowMd && (
                        <>
                          <Stack direction="row" spacing={3}>
                            <ButtonBase
                              onClick={() =>
                                handleOpenNetworkDialog("follower")
                              }
                            >
                              <Stack
                                direction="column"
                                spacing={0}
                                textAlign="left"
                              >
                                <Typography>
                                  {" "}
                                  <b>{followerCount}</b>{" "}
                                </Typography>
                                <Typography> followers </Typography>
                              </Stack>
                            </ButtonBase>
                            <ButtonBase
                              onClick={() =>
                                handleOpenNetworkDialog("following")
                              }
                            >
                              <Stack
                                direction="column"
                                spacing={0}
                                textAlign="left"
                              >
                                <Typography>
                                  {" "}
                                  <b>{followingCount}</b>{" "}
                                </Typography>
                                <Typography> following </Typography>
                              </Stack>
                            </ButtonBase>
                          </Stack>
                          <Divider sx={{ mb: 1, mt: 1 }} />{" "}
                        </>
                      )}

                      <Typography>{profile.bio}</Typography>
                    </Box>
                  </Popover>
                </Grid>
                <Grid
                  item
                  xs={12}
                  md={4}
                  sx={{ borderBottom: 1, borderColor: "divider" }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "flex-end",
                      alignItems: "flex-end",
                      height: "100%",
                    }}
                  >
                    <Tabs
                      value={activeTab}
                      onChange={handleTabChange}
                      aria-label="Profile content tabs"
                    >
                      {tabData.map((tab, index) => (
                        <Tab
                          key={index}
                          label={
                            <Box sx={{ display: "flex", alignItems: "center" }}>
                              {tab.icon}
                              <Typography variant="body2" sx={{ ml: 1 }}>
                                {tab.count}
                              </Typography>
                            </Box>
                          }
                          value={tab.value}
                          disabled={tab.value !== "movie"}
                        />
                      ))}
                    </Tabs>
                  </Box>
                </Grid>
                <Grid
                  item
                  xs={12}
                  md={8}
                  display="flex"
                  justifyContent="space-between"
                >
                  {!isBelowMd && (
                    <Stack
                      direction="row"
                      spacing={1}
                      sx={{ pt: 1.5, alignItems: "center" }}
                    >
                      <PeopleIcon />
                      <ButtonBase
                        onClick={() => handleOpenNetworkDialog("follower")}
                      >
                        <Typography sx={{ pr: 1 }}>
                          {" "}
                          <b>{followerCount}</b> followers
                        </Typography>
                      </ButtonBase>

                      <PeopleIcon />
                      <ButtonBase
                        onClick={() => handleOpenNetworkDialog("following")}
                      >
                        <Typography sx={{ pr: 1 }}>
                          {" "}
                          <b>{followingCount}</b> following
                        </Typography>
                      </ButtonBase>
                    </Stack>
                  )}
                </Grid>
                <Grid item xs={12} md={4}>
                  <Stack
                    direction="row"
                    spacing={1}
                    sx={{ pt: 1.5, alignItems: "center" }}
                    justifyContent="flex-end"
                  >
                    {statusOptions?.queued.icon}
                    <Typography sx={{ pr: 1 }}>
                      {" "}
                      {profile.libraryCounts.movie.queued}{" "}
                    </Typography>
                    {statusOptions?.started.icon}
                    <Typography sx={{ pr: 1 }}>
                      {" "}
                      {profile.libraryCounts.movie.started}{" "}
                    </Typography>
                    {statusOptions?.abandoned.icon}
                    <Typography sx={{ pr: 1 }}>
                      {" "}
                      {profile.libraryCounts.movie.abandoned}{" "}
                    </Typography>
                    {statusOptions.finished.icon}
                    <Typography sx={{ pr: 1 }}>
                      {" "}
                      {profile.libraryCounts.movie.finished}{" "}
                    </Typography>
                  </Stack>
                </Grid>
              </Grid>

              <ListMenu
                anchorEl={menuAnchorEl}
                onClose={() => setMenuAnchorEl(null)}
                onSelect={handleMenuSelect}
                options={menuOptions}
              />

              <Dialog
                open={openNetworkDialog}
                onClose={handleCloseNetworkDialog}
                fullWidth
                maxWidth="sm"
                fullScreen={isBelowSm}
                sx={{
                  "& .MuiDialog-paper": {
                    height: isBelowSm ? undefined : "300px",
                  },
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                  }}
                >
                  <Tabs
                    value={activeNetworkTab}
                    onChange={handleNetworkTabChange}
                    selectionFollowsFocus
                    variant="scrollable"
                    scrollButtons="auto"
                  >
                    <Tab
                      label={`Followers (${profile.followers.length})`}
                      value="follower"
                    />
                    <Tab
                      label={`Following (${profile.following.length})`}
                      value="following"
                    />
                    <Tab label="Search" value="search" />
                  </Tabs>
                  <IconButton
                    aria-label="close"
                    size="small"
                    onClick={handleCloseNetworkDialog}
                    sx={{
                      // position: "absolute",
                      // right: 8,
                      // top: 8,
                      color: (theme) => theme.palette.grey[500],
                      mr: 1,
                    }}
                  >
                    <CloseIcon />
                  </IconButton>
                </Box>

                <DialogContent dividers sx={{ p: 0 }}>
                  {activeNetworkTab === "follower" ? (
                    <List>
                      {profile.followers.length > 0 ? (
                        profile.followers.map((follower) => (
                          <ListItem key={follower.id}>
                            <ListItemButton
                              onClick={() =>
                                handleDialogSelect(follower.username)
                              }
                            >
                              <ListItemAvatar>
                                <Avatar src={follower.picture} />
                              </ListItemAvatar>
                              <ListItemText primary={follower.username} />
                            </ListItemButton>
                          </ListItem>
                        ))
                      ) : (
                        <Box textAlign="center" py={2}>
                          {isOwnProfile ? (
                            "You have no followers yet"
                          ) : (
                            <>
                              <i>@{profile.username}</i> has no followers yet
                            </>
                          )}
                        </Box>
                      )}
                    </List>
                  ) : activeNetworkTab === "following" ? (
                    <List>
                      {profile.following.length > 0 ? (
                        profile.following.map((followed) => (
                          <ListItem key={followed.id}>
                            <ListItemButton
                              onClick={() =>
                                handleDialogSelect(followed.username)
                              }
                            >
                              <ListItemAvatar>
                                <Avatar src={followed.picture} />
                              </ListItemAvatar>
                              <ListItemText primary={followed.username} />
                            </ListItemButton>
                          </ListItem>
                        ))
                      ) : (
                        <Box textAlign="center" py={2}>
                          <Typography>
                            {isOwnProfile ? (
                              "You are not following anyone yet"
                            ) : (
                              <>
                                <i>@{profile.username}</i> is not following
                                anyone yet
                              </>
                            )}
                          </Typography>
                        </Box>
                      )}
                    </List>
                  ) : (
                    activeNetworkTab === "search" && (
                      <Box textAlign="center" p={2}>
                        <UserSearch fullscreen={isBelowSm} />
                      </Box>
                    )
                  )}
                </DialogContent>
              </Dialog>
            </Toolbar>
          </Container>
        </AppBar>
      </HideOnScroll>
    </>
  );
};

export default ProfileHeader;
