import React from "react";
import { useThemeContext } from "../../context/themeContext";
import {
  Box,
  Card,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Stack,
  SvgIcon,
  Typography,
} from "@mui/material";
import { ReactComponent as TrackIcon } from "../../assets/IconTrack.svg";
import { getStatusOptionsArray } from "../../utils/utils";

export default function EmptyLibrary({ filtersApplied, status }) {
  const statuses = getStatusOptionsArray();
  const { mode } = useThemeContext();
  return (
    <Card
      sx={{
        width: "100%",
        p: 2,
        maxWidth: "sm",
        display: "flex",
        flexDirection: "column",
        boxShadow: "none",
        bgcolor: (theme) =>
          mode === "dark"
            ? theme.palette.background.paper
            : theme.palette.secondary.main,
      }}
    >
      <Stack
        direction={{ xs: "column", sm: "row" }}
        alignItems={{
          xs: "center",
          sm: filtersApplied ? "center" : "flex-start",
        }}
        justifyContent={{ xs: "center", md: "flex-start" }}
        spacing={2}
      >
        <SvgIcon
          sx={{
            width: { xs: 75, sm: 100 },
            height: { xs: 75, sm: 100 },
            color: "primary.main",
          }}
        >
          <TrackIcon />
        </SvgIcon>
        {filtersApplied ? (
          <Typography>
            {" "}
            No movies in your library match your current filters. Try again or
            search outside your library using the add button below!{" "}
          </Typography>
        ) : (
          <>
            <Box sx={{ display: "flex", flexDirection: "column" }}>
              <Typography>
                Welcome to the Track page! This is the living library of your
                content consumption. Everything is organised by status:{" "}
              </Typography>
              <List sx={{ "& .MuiListItem-root": { py: 0 } }}>
                {statuses
                  .filter((statusOption) => statusOption.value !== "none") // Filter out "none"
                  .map((statusOption) => (
                    <ListItem key={statusOption.value}>
                      <ListItemIcon>
                        {statusOption.value === status
                          ? statusOption.iconFilled
                          : statusOption.icon}
                      </ListItemIcon>
                      <ListItemText>
                        {statusOption.label}{" "}
                        {statusOption.value === status && "*"}
                      </ListItemText>
                    </ListItem>
                  ))}
              </List>
              <Typography gutterBottom>
                {" "}
                Your queue is your "to watch, read, listen and see" list, all in
                one place. Say goodbye to those scattered recommendations buried
                in your notes app!
              </Typography>
              <Typography gutterBottom>
                {" "}
                The other statuses speak for themselves, helping you track your
                progress through the infinite content-verse.
              </Typography>
              <Typography
                variant="caption"
                sx={{ lineHeight: "0.75rem", mt: 1 }}
              >
                {" "}
                * Currently selected
              </Typography>
            </Box>
          </>
        )}
      </Stack>
    </Card>
  );
}
