import { Outlet } from "react-router-dom";
import ScrollToTop from "../components/ScrollToTop";
import { AuthProvider } from "../context/authContext";
import { ToastContainer } from "react-toastify";
import { useMediaQuery, useTheme } from "@mui/material";

export default function RootLayout() {
  const theme = useTheme();
  // const location = useLocation();
  // const showRootToastContainer = !location.pathname.startsWith("/app");
  // console.log("showRootToastContainer = ", showRootToastContainer);
  const isLessThan480 = useMediaQuery("(max-width:480px)");

  return (
    <AuthProvider>
      <ScrollToTop />
      <Outlet />
      {/* {showRootToastContainer && ( */}
      <ToastContainer
        position="top-right"
        autoClose={2000}
        hideProgressBar
        newestOnTop
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme={theme.palette.mode}
        style={{ marginTop: isLessThan480 ? "16px" : "0px" }}
      />
      {/* )} */}
    </AuthProvider>
  );
}
