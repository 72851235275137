import React, { useCallback } from "react";
import {
  Search,
  SearchIconWrapper,
  StyledInputBase,
} from "../components/CustomMUI.js";
import SearchIcon from "@mui/icons-material/Search";
import CloseIcon from "@mui/icons-material/Close";
import { IconButton, InputAdornment } from "@mui/material";
import { useDebounce } from "../hooks/useDebounce.js";

export default function SearchFilter({ searchTerm, onSearchTermChange }) {
  const [inputValue, setInputValue] = React.useState(searchTerm || "");
  const [isFocused, setIsFocused] = React.useState(false);

  const debouncedSearchTermChange = useDebounce(onSearchTermChange, 300);

  const handleChange = (event) => {
    setInputValue(event.target.value);
    debouncedSearchTermChange(event.target.value);
  };

  const handleKeyDown = useCallback(
    (event) => {
      if (event.key === "Escape") {
        setInputValue("");
        onSearchTermChange("");
        event.target.blur();
      }
    },
    [onSearchTermChange]
  );

  const handleClear = () => {
    setInputValue("");
    onSearchTermChange("");
  };

  return (
    <Search highlight={!!inputValue}>
      <SearchIconWrapper highlight={!!inputValue}>
        <SearchIcon />
      </SearchIconWrapper>

      <StyledInputBase
        id="search-library-by-title"
        placeholder={inputValue ? "" : "Filter by title"}
        inputProps={{ "aria-label": "search", autoComplete: "off" }}
        value={inputValue}
        onChange={handleChange}
        onKeyDown={handleKeyDown}
        onFocus={() => setIsFocused(true)}
        onBlur={() => setIsFocused(false)}
        endAdornment={
          isFocused || inputValue ? (
            <InputAdornment position="end" sx={{ pr: 1 }}>
              <IconButton
                aria-label="clear search"
                onClick={handleClear}
                edge="end"
                size="small"
                sx={{ visibility: inputValue ? "visible" : "hidden" }}
              >
                <CloseIcon fontSize="small" />
              </IconButton>
            </InputAdornment>
          ) : undefined
        }
      />
    </Search>
  );
}
