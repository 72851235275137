import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import PlayCircleOutlineIcon from "@mui/icons-material/PlayCircleOutline";
import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline";
import WatchLaterOutlinedIcon from "@mui/icons-material/WatchLaterOutlined";
import TodayIcon from "@mui/icons-material/Today";
import StarRateIcon from "@mui/icons-material/StarRate";
import EventAvailableIcon from "@mui/icons-material/EventAvailable";
import TheaterComedyIcon from "@mui/icons-material/TheaterComedy";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import AddIcon from "@mui/icons-material/Add";
import RefreshIcon from "@mui/icons-material/Refresh";
import RemoveIcon from "@mui/icons-material/Remove";
import OpenInFullIcon from "@mui/icons-material/OpenInFull";
import SpeedIcon from "@mui/icons-material/Speed";
import MovieRoundedIcon from "@mui/icons-material/MovieRounded";
import MenuBookRoundedIcon from "@mui/icons-material/MenuBookRounded";
import LiveTvRoundedIcon from "@mui/icons-material/LiveTvRounded";
import PodcastsRoundedIcon from "@mui/icons-material/PodcastsRounded";
import WatchLaterIcon from "@mui/icons-material/WatchLater";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import PlayCircleIcon from "@mui/icons-material/PlayCircle";
import RemoveCircleIcon from "@mui/icons-material/RemoveCircle";
import CategoryIcon from "@mui/icons-material/Category";

// Function to format dates as 'dd mmm yyyy'
export const formatDate = (dateString, format) => {
  const date = new Date(dateString);
  if (format === "dropDay") {
    return date.toLocaleDateString("en-GB", {
      month: "short",
      year: "numeric",
    });
  }
  return date.toLocaleDateString("en-GB", {
    day: "2-digit",
    month: "short",
    year: "numeric",
  });
};

// Function to get year from full date
export const getYear = (date) => {
  return date ? new Date(date).getFullYear() : null;
};

export const getContentOptions = ({ showEverythingOption = false } = {}) => {
  const options = {
    movie: {
      label: "Movies",
      icon: <MovieRoundedIcon />,
    },
    tvshow: {
      label: "TV shows",
      icon: <LiveTvRoundedIcon />,
      disabled: true,
    },
    book: {
      label: "Books",
      icon: <MenuBookRoundedIcon />,
      disabled: true,
    },
    podcast: {
      label: "Podcasts",
      icon: <PodcastsRoundedIcon />,
      disabled: true,
    },
  };

  if (showEverythingOption) {
    return {
      all: {
        label: "All",
        icon: <CategoryIcon />,
        disabled: false,
      },
      ...options,
    };
  }

  return options;
};

export const getContentOptionsArray = ({ showEverythingOption = false } = {}) =>
  Object.entries(getContentOptions({ showEverythingOption })).map(
    ([value, option]) => ({
      value,
      ...option,
    })
  );

export const getStatusOptions = (theme = null) => ({
  queued: {
    label: "Queued",
    icon: (
      <WatchLaterOutlinedIcon
        sx={{ color: (theme) => theme.palette.status.queued.main }}
      />
    ),
    iconWhite: <WatchLaterOutlinedIcon />,
    iconFilled: (
      <WatchLaterIcon
        sx={{ color: (theme) => theme.palette.status.queued.main }}
      />
    ),
    color: theme ? theme.palette.status.queued.main : undefined,
    contrastText: theme ? theme.palette.status.queued.contrastText : undefined,
    action: "Queue",
  },
  started: {
    label: "Started",
    icon: (
      <PlayCircleOutlineIcon
        sx={{ color: (theme) => theme.palette.status.started.main }}
      />
    ),
    iconWhite: <PlayCircleOutlineIcon />,
    iconFilled: (
      <PlayCircleIcon
        sx={{ color: (theme) => theme.palette.status.started.main }}
      />
    ),
    color: theme ? theme.palette.status.started.main : undefined,
    contrastText: theme ? theme.palette.status.started.contrastText : undefined,
    action: "Start",
  },
  abandoned: {
    label: "Abandoned",
    icon: (
      <RemoveCircleOutlineIcon
        sx={{ color: (theme) => theme.palette.status.abandoned.main }}
      />
    ),
    iconWhite: <RemoveCircleOutlineIcon />,
    iconFilled: (
      <RemoveCircleIcon
        sx={{ color: (theme) => theme.palette.status.abandoned.main }}
      />
    ),
    color: theme ? theme.palette.status.abandoned.main : undefined,
    contrastText: theme
      ? theme.palette.status.abandoned.contrastText
      : undefined,
    action: "Abandon",
  },
  finished: {
    label: "Finished",
    icon: (
      <CheckCircleOutlineIcon
        sx={{ color: (theme) => theme.palette.status.finished.main }}
      />
    ),
    iconWhite: <CheckCircleOutlineIcon />,
    iconFilled: (
      <CheckCircleIcon
        sx={{ color: (theme) => theme.palette.status.finished.main }}
      />
    ),
    color: theme ? theme.palette.status.finished.main : undefined,
    contrastText: theme
      ? theme.palette.status.finished.contrastText
      : undefined,
    action: "Finish",
  },
  // logged: {
  //   label: "Logged",
  //   icon: <CheckCircleIcon />,
  //   color: theme ? theme.palette.success.light : undefined,
  //   action: "Log",
  // },
  none: {
    label: "Add",
    icon: <AddIcon />,
    color: theme ? theme.palette.primary : undefined,
    action: "Add",
  },
});

export const getStatusOptionsArray = (theme) =>
  Object.entries(getStatusOptions(theme)).map(([value, option]) => ({
    value,
    ...option,
  }));

export const getSortByOptions = (status) => [
  {
    label: "Log date",
    value: "updated_at",
    icon: <TodayIcon fontSize="small" />,
    disabled: false,
  },
  {
    label: "Watch date",
    value: "watch_date",
    icon: <EventAvailableIcon fontSize="small" />,
    disabled: status !== "finished",
  },
  {
    label: "Rating",
    value: "rating",
    icon: <StarRateIcon fontSize="small" />,
    disabled: status !== "finished",
  },
];

export const getFilterOptions = (status) => [
  {
    label: "Genre",
    value: "Genre",
    icon: <TheaterComedyIcon />,
    disabled: false,
  },
  {
    label: "Decade",
    value: "Decade",
    icon: <CalendarMonthIcon />,
    disabled: false,
  },
  {
    label: "Rating",
    value: "Rating",
    icon: <StarRateIcon />,
    disabled: status !== "finished",
  },
];

export const genreOptions = [
  { label: "Drama", value: "Drama" },
  { label: "Crime", value: "Crime" },
  { label: "History", value: "History" },
  { label: "War", value: "War" },
  { label: "Comedy", value: "Comedy" },
  { label: "Romance", value: "Romance" },
  { label: "Animation", value: "Animation" },
  { label: "Family", value: "Family" },
  { label: "Fantasy", value: "Fantasy" },
  { label: "Action", value: "Action" },
  { label: "Thriller", value: "Thriller" },
  { label: "Adventure", value: "Adventure" },
  { label: "Western", value: "Western" },
  { label: "Music", value: "Music" },
  { label: "Horror", value: "Horror" },
  { label: "Mystery", value: "Mystery" },
  { label: "Science Fiction", value: "Science Fiction" },
  { label: "TV Movie", value: "TV Movie" },
];

export const decadeOptions = [
  { label: "2020s", value: 2020 },
  { label: "2010s", value: 2010 },
  { label: "2000s", value: 2000 },
  { label: "1990s", value: 1990 },
  { label: "1980s", value: 1980 },
  { label: "1970s", value: 1970 },
  { label: "1960s", value: 1960 },
  { label: "1950s", value: 1950 },
  { label: "1940s", value: 1940 },
  { label: "1930s", value: 1930 },
  { label: "1920s", value: 1920 },
  { label: "1910s", value: 1910 },
  { label: "1900s", value: 1900 },
];

export const reactionOptions = [
  { label: "slow-paced", category: "Pacing", rank: 1, icon: <SpeedIcon /> },
  { label: "medium-paced", category: "Pacing", rank: 1, icon: <SpeedIcon /> },
  { label: "fast-paced", category: "Pacing", rank: 1, icon: <SpeedIcon /> },
  { label: "cute", category: "Reactions", rank: 1, emoji: "😍" },
  { label: "aesthetic", category: "Reactions", rank: 1, emoji: "🎨" },
  { label: "funny", category: "Reactions", rank: 1, emoji: "😂" },
  { label: "infuriating", category: "Reactions", rank: 1, emoji: "😡" },
  { label: "worrying", category: "Reactions", rank: 1, emoji: "😟" },
  { label: "awe-inspiring", category: "Reactions", rank: 1, emoji: "😲" },
  { label: "awkward", category: "Reactions", rank: 1, emoji: "😳" },
  { label: "boring", category: "Reactions", rank: 1, emoji: "😴" },
  { label: "chill", category: "Reactions", rank: 1, emoji: "😎" },
  { label: "confusing", category: "Reactions", rank: 1, emoji: "🤯" },
  { label: "disgusting", category: "Reactions", rank: 1, emoji: "🤢" },
  { label: "distressing", category: "Reactions", rank: 1, emoji: "😖" },
  { label: "gripping", category: "Reactions", rank: 1, emoji: "🤩" },
  { label: "exciting", category: "Reactions", rank: 1, emoji: "😄" },
  { label: "scary", category: "Reactions", rank: 1, emoji: "😱" },
  { label: "disturbing", category: "Reactions", rank: 1, emoji: "😨" },
  { label: "interesting", category: "Reactions", rank: 1, emoji: "🤔" },
  { label: "happy", category: "Reactions", rank: 1, emoji: "😊" },
  { label: "nostalgic", category: "Reactions", rank: 1, emoji: "😶‍🌫️" },
  { label: "comforting", category: "Reactions", rank: 1, emoji: "😌" },
  { label: "romantic", category: "Reactions", rank: 1, emoji: "🥰" },
  { label: "sad", category: "Reactions", rank: 1, emoji: "😢" },
  { label: "satisfying", category: "Reactions", rank: 1, emoji: "😌" },
  { label: "sexual", category: "Reactions", rank: 1, emoji: "😛" },
  { label: "surprising", category: "Reactions", rank: 1, emoji: "😮" },
  { label: "acting", category: "Kudos", rank: 1, emoji: "👏" },
  { label: "writing", category: "Kudos", rank: 2, emoji: "👏" },
  // { label: "character development", category: "Kudos", rank: 3, emoji: "👏" },
  { label: "directing", category: "Kudos", rank: 4, emoji: "👏" },
  // { label: "worldbuilding", category: "Kudos", rank: 5, emoji: "👏" },
  { label: "soundtrack", category: "Kudos", rank: 6, emoji: "👏" },
  { label: "editing", category: "Kudos", rank: 7, emoji: "👏" },
  { label: "vfx", category: "Kudos", rank: 8, emoji: "👏" },
  { label: "sound design", category: "Kudos", rank: 10, emoji: "👏" },
  { label: "costume design", category: "Kudos", rank: 11, emoji: "👏" },
  { label: "set design", category: "Kudos", rank: 12, emoji: "👏" },
];

export const tileHoverOptions = [
  {
    label: "Replace",
    value: "replace",
    icon: <RefreshIcon />,
  },
  {
    label: "Remove",
    value: "remove",
    icon: <RemoveIcon />,
  },
  {
    label: "Open",
    value: "open",
    icon: <OpenInFullIcon />,
  },
];
