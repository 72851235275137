import React, { createContext, useContext, useMemo } from "react";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import useApi from "../hooks/useApi.js";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { toast } from "react-toastify";
import Loading from "../components/Loading.js";

// Create a context
const AuthContext = createContext();

// Hook for child components to get the auth object and re-render when it changes
export const useAuth = () => {
  return useContext(AuthContext);
};

// Provider component that wraps your app and makes auth object available to any child component that calls useAuth()
export const AuthProvider = ({ children }) => {
  const { authenticateUser, logoutUser } = useApi();
  const isCookiePresent = document.cookie.includes("csrf_access_token");
  const queryClient = useQueryClient();
  const navigate = useNavigate();

  const userAuthQuery = useQuery({
    queryKey: ["currentUser"],
    queryFn: () => authenticateUser(),
    enabled: isCookiePresent,
    retry: false,
    staleTime: Infinity, // 2 hours
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
    refetchOnMount: false,
  });

  const user = useMemo(
    () => userAuthQuery.data?.userDetails || null,
    [userAuthQuery.data?.userDetails]
  );

  console.log("user = ", !!user);

  const login = async () => {
    try {
      await axios.get(`${process.env.REACT_APP_BACKEND_URL}/`, {
        timeout: 5000,
      });

      // If the backend is reachable, proceed with the redirect
      window.location.href = `${process.env.REACT_APP_BACKEND_URL}/oauth2/authorize`;
    } catch (error) {
      toast.error("Cannot login right now. please try again later");
    }
  };

  const logout = async () => {
    await logoutUser();
    queryClient.setQueryData(["currentUser"], null);
    navigate("/");
  };

  if (userAuthQuery.isLoading) {
    return <Loading />;
  }

  // The value prop of the provider will be our context value
  // In this case, the current user and functions to manipulate it
  const value = {
    user,
    login,
    logout,
  };

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};
