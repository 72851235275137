import React from "react";
import { Card, CardActionArea, Grid, Stack, Typography } from "@mui/material";
import PosterTile from "../../components/PosterTile";
import MovieRoundedIcon from "@mui/icons-material/MovieRounded";

export default function CollectionCover({
  list,
  reverse = true,
  onClick,
  isMobile,
}) {
  return (
    <Card
      elevation={0}
      onClick={onClick}
      sx={{
        width: "100%",
        margin: "0 auto",
      }}
    >
      <CardActionArea sx={{ p: 1.5 }}>
        <Grid container spacing={2}>
          <Grid
            item
            xs={12}
            sm={6}
            order={{ xs: !reverse ? 2 : 1, sm: reverse ? 2 : 1 }}
            display="flex"
            flexDirection="column"
            gap={1}
          >
            <Typography
              variant="h6"
              sx={{
                fontWeight: "bold",
                textWrap: "balance",
                pt: 0.5,
                display: "-webkit-box",
                overflow: "hidden",
                WebkitBoxOrient: "vertical",
                WebkitLineClamp: 3,
                textAlign: { xs: "center", sm: reverse ? "left" : "right" },
              }}
            >
              {list.title}
            </Typography>
            {list.description && (
              <Typography
                variant="body2"
                sx={{
                  textWrap: "balance",
                  display: "-webkit-box",
                  overflow: "hidden",
                  WebkitBoxOrient: "vertical",
                  WebkitLineClamp: 5,
                  textAlign: { xs: "center", sm: reverse ? "left" : "right" },
                }}
              >
                {list.description}
              </Typography>
            )}
            {!isMobile && (
              <Stack
                direction={reverse ? "row" : "row-reverse"}
                spacing={1}
                alignItems="center"
                justifyContent="flex-start"
              >
                <MovieRoundedIcon />
                <Typography variant="body2">{list.items.length}</Typography>
              </Stack>
            )}
          </Grid>

          <Grid item xs={12} sm={6} order={reverse ? 1 : 2}>
            {list.items.length > 0 ? (
              <Stack
                spacing={"-10%"}
                sx={{
                  justifyContent: { xs: "center", sm: "flex-start" },
                  "& > *": {
                    flexBasis: `calc(150% / 5)`,
                    maxWidth: `calc(150% / 5)`,
                  },
                }}
                direction={{
                  xs: "row-reverse",
                  sm: reverse ? "row-reverse" : "row",
                }}
              >
                {list.items.slice(0, 5).map((item, index) => (
                  <PosterTile
                    contentId={item.content_id}
                    key={item.content_id}
                    padding={{ xs: 0.5, sm: 0.75 }}
                    zIndex={100 - index}
                  />
                ))}
              </Stack>
            ) : (
              <Stack
                direction={{
                  xs: "row-reverse",
                  sm: reverse ? "row-reverse" : "row",
                }}
                spacing={"-10%"}
              >
                {Array(5)
                  .fill()
                  .map((_, index) => (
                    <PosterTile
                      key={index}
                      skeleton={true}
                      padding={0.75}
                      zIndex={100 - index}
                    />
                  ))}
              </Stack>
            )}
          </Grid>
        </Grid>
      </CardActionArea>
    </Card>
  );
}
