import { createBrowserRouter } from "react-router-dom";
import Home from "./Home";
import Profile from "./Profile";
import ProtectedRoute from "./ProtectedRoute";
import PrivacyPolicy from "./PrivacyPolicy";
import Spa from "./Spa";
import Track from "./Track";
import Curate from "./Curate";
import Explore from "./Explore";
import { Container } from "@mui/material";
import RootLayout from "./RootLayout";
import ErrorPage from "./ErrorPage";
import NotFoundPage from "./NotFoundPage";

const router = createBrowserRouter([
  {
    path: "/",
    element: <RootLayout />,
    errorElement: <ErrorPage />,
    children: [
      { index: true, element: <Home /> },
      {
        path: "explore/user/:username",
        element: (
          <Container maxWidth="lg">
            <Profile />
          </Container>
        ),
      },
      { path: "privacy", element: <PrivacyPolicy /> },
      {
        path: "app",
        element: (
          <ProtectedRoute>
            <Spa />
          </ProtectedRoute>
        ),
        children: [
          { path: "track", element: <Track /> },
          { path: "curate", element: <Curate /> },
          { path: "explore", element: <Explore /> },
          {
            path: "explore/user/:username",
            element: <Profile />,
          },
        ],
      },
      {
        path: "*",
        element: <NotFoundPage />,
      },
    ],
  },
]);

export default router;
