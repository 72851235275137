import React, { useState, useEffect, useRef } from "react";
import TextField from "@mui/material/TextField";
import {
  Autocomplete,
  Stack,
  Typography,
  useTheme,
  Box,
  Popover,
  LinearProgress,
  ListItemText,
  Menu,
  MenuItem,
  ListItemIcon,
  IconButton,
} from "@mui/material";
import useApi from "../hooks/useApi.js";
import { getStatusOptionsArray, getYear } from "../utils/utils.js";
import CloseIcon from "@mui/icons-material/Close";
import MouseIcon from "@mui/icons-material/Mouse";
import TouchAppIcon from "@mui/icons-material/TouchApp";
import KeyboardIcon from "@mui/icons-material/Keyboard";
import QuestionMarkIcon from "@mui/icons-material/QuestionMark";
import { useAuth } from "../context/authContext.js";
import ButtonBase from "@mui/material/ButtonBase";
import { useDebounce } from "../hooks/useDebounce.js";
import { toast } from "react-toastify";

export default function SearchBar({
  contentType,
  onQuickAdd,
  onSelect,
  onClose,
  fullscreen,
  enableQuickAdd = true,
  title,
}) {
  const { user } = useAuth();
  const theme = useTheme();
  const statusOptionsArray = getStatusOptionsArray(theme).filter(
    (option) => option.value !== "none"
  );
  const { fetchContentAutocomplete, fetchContentSearch, addContent } = useApi();
  const [options, setOptions] = useState([]); // Autocomplete dropdown options
  const [searchInput, setSearchInput] = useState(""); // Input value of searchbar
  const [focusedOption, setFocusedOption] = useState(null); // Index of focused option
  const [openAutocompleteDropdown, setOpenAutocompleteDropdown] =
    useState(true);
  const [infoPopupAnchorEl, setInfoPopupAnchorEl] = useState(null);
  const [contextMenu, setContextMenu] = useState(null); // {mouseX, mouseY, selection}
  const [isLoading, setIsLoading] = useState(false); // loading state for fetchMovieSearch
  const timerRef = useRef(null); // timer for long press
  const [longPressedOption, setLongPressedOption] = useState(null); // to enable conditional highlighting when long pressed
  const isMobile = "ontouchstart" in window || navigator.maxTouchPoints > 0; // to conditionally show help specific to desktop vs mobile

  const debouncedAutocomplete = useDebounce(async (query) => {
    try {
      const data = await fetchContentAutocomplete(query, contentType);
      setOptions([
        {
          label: `Search for "${query}"`,
          searchFor: true,
          value: query,
        },
        ...data,
      ]);
    } catch (error) {
      console.error("Error fetching autocomplete data:", error);
    }
  }, 300);

  useEffect(() => {
    if (searchInput.length > 2) {
      debouncedAutocomplete(searchInput);
    } else {
      debouncedAutocomplete.cancel();
      setOptions([]);
    }

    return () => debouncedAutocomplete.cancel();
  }, [searchInput, debouncedAutocomplete]);

  const handleSearchFor = async (searchValue) => {
    try {
      setIsLoading(true);
      setFocusedOption(null);
      setOpenAutocompleteDropdown(false);

      const response = await fetchContentSearch(searchValue, contentType);

      const newOptions = response.map((result) => ({
        title: result.title,
        detail: result.detail,
        external_id: result.external_id,
      }));

      if (newOptions.length > 0) {
        setOptions(newOptions);
      } else {
        setOptions([{ noResults: true, label: "No results found" }]);
      }
    } catch (error) {
      console.error("Error performing movie search: ", error);
    } finally {
      setIsLoading(false);
      setOpenAutocompleteDropdown(true);
    }
  };

  const handleSelect = async (selectedOption) => {
    // if selectedOption has external_id property, content needs to be added to database
    // if it doesn't have external_id then it can be passed to onSelect function directly
    if (selectedOption.external_id) {
      try {
        const response = await addContent(
          contentType,
          selectedOption.external_id
        );
        if (response.success) {
          onSelect(response.content_id, contentType);
        } else {
          throw new Error(response.message);
        }
      } catch (error) {
        toast.error(error.response?.data?.message);
      }
    } else {
      onSelect(selectedOption.content_id, contentType);
    }
  };

  const handleQuickAdd = async (selectedOption, status) => {
    if (selectedOption.external_id) {
      try {
        const response = await addContent(
          contentType,
          selectedOption.external_id
        );
        if (response.success) {
          onQuickAdd(response.content_id, status, contentType);
        } else {
          throw new Error(response.message);
        }
      } catch (error) {
        toast.error(error.response?.data?.message);
      }
    } else {
      onQuickAdd(selectedOption.content_id, status, contentType);
    }
  };

  const handleContextMenuOpen = (event, option) => {
    event.preventDefault();
    event.stopPropagation();
    setContextMenu(
      contextMenu === null
        ? {
            mouseX: event.clientX + 2,
            mouseY: event.clientY - 6,
            selection: option,
          }
        : null
    );
  };

  const handleTouchStart = (event, option) => {
    event.preventDefault(); // Prevent scrolling during long press
    timerRef.current = setTimeout(() => {
      const touch = event.touches[0];
      setLongPressedOption(option);
      setContextMenu(
        contextMenu === null
          ? {
              mouseX: touch.clientX - 120,
              mouseY: touch.clientY - 50,
              selection: option,
            }
          : null
      );
    }, 500); // 500ms long-press threshold
  };

  const handleTouchEnd = () => {
    clearTimeout(timerRef.current);
  };

  const handleContextMenuClose = () => {
    setLongPressedOption(null);
    setContextMenu(null);
  };

  return (
    <Box>
      <Stack
        direction="row"
        alignItems="center"
        justifyContent="space-between"
        sx={{ mb: 1 }}
      >
        <Typography variant="body2">{title}</Typography>
        <Box display="flex" alignItems="center">
          {enableQuickAdd && (
            <IconButton
              onClick={(event) => setInfoPopupAnchorEl(event.currentTarget)}
              size="small"
            >
              <QuestionMarkIcon fontSize="small" />
            </IconButton>
          )}
          {fullscreen && (
            <IconButton onClick={onClose} size="small" sx={{ ml: 1 }}>
              {" "}
              <CloseIcon />{" "}
            </IconButton>
          )}
        </Box>
      </Stack>

      <Autocomplete
        id="autocomplete-search-bar"
        freeSolo
        clearOnEscape
        fullWidth
        // blurOnSelect
        inputValue={searchInput}
        options={options}
        open={openAutocompleteDropdown}
        ListboxProps={{ sx: { maxHeight: "35vh" } }} // Todo: replace with slotProps when upgrading to new version of MUI (current version does not support slotProps but next version requires it)!!!
        getOptionLabel={(option) => {
          if (
            typeof option === "object" &&
            (option.searchFor || option.title || option.noResults)
            // Required because when user presses enter without interacting with autocomplete dropdown, the option itself is passed
            // to getOptionLabel as a string and therefore doesn't have .value and .title properties
          ) {
            return option.searchFor
              ? option.value
              : option.noResults
              ? option.label
              : option.title;
          }
          return option;
        }}
        renderOption={(props, option) => {
          // Determines how the options are displayed in the Autocomplete dropdown

          if (option.searchFor) {
            return (
              <li {...props} onClick={() => handleSearchFor(option.value)}>
                <Typography fontWeight="bold">{option.label}</Typography>
              </li>
            );
          }
          if (option.noResults) {
            return (
              <li {...props} style={{ pointerEvents: "none" }}>
                <Typography fontWeight="bold">{option.label}</Typography>
              </li>
            );
          }
          return (
            <ButtonBase
              component="li"
              className="allow-context-menu"
              {...props}
              onClick={() => handleSelect(option)}
              {...(enableQuickAdd && {
                onContextMenu: (e) => handleContextMenuOpen(e, option),
                onTouchStart: (e) => handleTouchStart(e, option),
                onTouchEnd: handleTouchEnd,
                onTouchMove: handleTouchEnd,
              })}
              key={option.content_id || `ext-${option.external_id}`}
              style={{
                position: "relative",
                overflow: "hidden",
                backgroundColor:
                  longPressedOption === option && theme.palette.action.hover,
              }}
            >
              <Typography>
                {option.title}{" "}
                <Typography
                  variant="body2"
                  component="span"
                  sx={{ display: "inline", ml: 1 }}
                >
                  <i>{getYear(option.detail)}</i>
                </Typography>
              </Typography>
            </ButtonBase>
          );
        }}
        filterOptions={(options) => options} // Needed to enable fuzzy matching to work, see here: https://stackoverflow.com/questions/68505566/autocomplete-not-rendering-as-expected-material-ui
        onInputChange={(event, newInputValue) => {
          setSearchInput(newInputValue); // Update the search input based on user input
          setOpenAutocompleteDropdown(true); // Needed to ensure dropdown is visible when user starts typing
        }}
        onHighlightChange={(event, option) => {
          setFocusedOption(option !== null ? options.indexOf(option) : null);
        }}
        onKeyDown={(event) => {
          if (focusedOption !== null) {
            const selectedOption = options[focusedOption];
            if (event.key === "Enter") {
              if (selectedOption.searchFor) {
                handleSearchFor(selectedOption.value);
              } else {
                handleSelect(selectedOption);
              }
            } else if (enableQuickAdd) {
              if (event.key === "q") {
                handleQuickAdd(selectedOption, "queued");
              } else if (event.key === "s") {
                handleQuickAdd(selectedOption, "started");
              } else if (event.key === "a") {
                handleQuickAdd(selectedOption, "abandoned");
              } else if (event.key === "f") {
                handleQuickAdd(selectedOption, "finished");
              }
            }
          } else if (event.key === "Enter") {
            handleSearchFor(searchInput);
          }
        }}
        renderInput={(params) => (
          <TextField
            {...params}
            autoFocus
            hiddenLabel={true}
            onBlur={() => {
              if (!contextMenu) {
                setOpenAutocompleteDropdown(false);
              }
            }}
            size="small"
            variant="filled"
            InputProps={{
              ...params.InputProps,
              disableUnderline: true,
              onClick: (e) => e.stopPropagation(),
              onFocus: (e) => e.stopPropagation(),
            }}
          />
        )}
      />

      {isLoading && <LinearProgress />}

      {enableQuickAdd && (
        <Popover
          id="mouse-over-popover"
          sx={{
            mt: 1,
          }}
          open={Boolean(infoPopupAnchorEl)}
          anchorEl={infoPopupAnchorEl}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "right",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
          onClose={() => setInfoPopupAnchorEl(null)}
          disableRestoreFocus={process.env.NODE_ENV === "development"}
        >
          <Box sx={{ p: 2 }} maxWidth={300}>
            <Stack direction="row" spacing={1} sx={{ mb: 1 }}>
              {isMobile ? (
                <TouchAppIcon fontSize="small" />
              ) : (
                <MouseIcon fontSize="small" />
              )}
              <Typography variant="body2">
                {!isMobile ? <b>Click</b> : <b>Tap</b>} a search result to mark
                it as {user.preferences.addButton}, or{" "}
                {!isMobile ? <b>right-click</b> : <b>long press</b>} to mark it
                as started, abandoned or{" "}
                {user.preferences.addButton === "finished"
                  ? "queued"
                  : "finished"}
              </Typography>
            </Stack>
            {!isMobile && (
              <Stack direction="row" spacing={1}>
                <KeyboardIcon fontSize="small" />
                <Typography variant="body2">
                  Use the <b>arrow keys</b> to highlight search result, then
                  press <b>q</b> to queue, <b>s</b> to start, <b>a</b> to
                  abandon or <b>f</b> to finish
                </Typography>
              </Stack>
            )}
          </Box>
        </Popover>
      )}

      <Menu
        open={contextMenu !== null}
        onClose={handleContextMenuClose}
        anchorReference="anchorPosition"
        anchorPosition={
          contextMenu !== null
            ? { top: contextMenu.mouseY, left: contextMenu.mouseX }
            : undefined
        }
      >
        {statusOptionsArray.map((menuOption, index) => (
          <MenuItem
            key={index}
            onClick={() => {
              handleQuickAdd(contextMenu.selection, menuOption.value);
              setContextMenu(null);
            }}
            dense
          >
            <ListItemIcon sx={{ color: menuOption.color }}>
              {menuOption.icon}
            </ListItemIcon>
            <ListItemText>{menuOption.action}</ListItemText>
          </MenuItem>
        ))}
      </Menu>
    </Box>
  );
}
