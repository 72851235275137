import { Box } from "@mui/material";
import React from "react";

export default function Loading({ inline = false }) {
  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: !inline ? "100vh" : "25px",
        width: !inline ? "100%" : "25px",
        flexDirection: "column",
      }}
    >
      <img
        src="/animations/Loading.gif"
        alt="Loading spinner"
        style={{
          width: !inline ? "50px" : "25px",
          height: !inline ? "50px" : "25px",
          pointerEvents: "none",
          objectFit: "contain",
        }}
      />
    </Box>
  );
}
