export const updateContentStatusInCache = ({
  queryClient,
  contentId,
  contentType,
  oldStatus,
  statusObject, // new status object received from backend
  entryObject, // new entry object associated with change of status received from backend
}) => {
  queryClient.setQueryData(["status", contentId], () => {
    return statusObject;
  });

  entryObject &&
    queryClient.setQueryData(["journal", contentId], (oldData) => {
      return oldData ? [entryObject, ...oldData] : [entryObject];
    });

  queryClient.setQueryData(["infFeed", contentType], (oldData) => {
    if (!oldData) return oldData; // if the activity doesn't exist, do nothing

    return {
      ...oldData,
      pages: oldData.pages.map((page) => ({
        ...page,
        items: page.items.map((item) =>
          item.content_id === contentId
            ? { ...item, user_status: statusObject.user_status }
            : item
        ),
      })),
    };
  });

  queryClient.setQueryData(["infFeed", "all"], (oldData) => {
    if (!oldData) return oldData; // if the activity doesn't exist, do nothing

    return {
      ...oldData,
      pages: oldData.pages.map((page) => ({
        ...page,
        items: page.items.map((item) =>
          item.content_id === contentId
            ? { ...item, user_status: statusObject.user_status }
            : item
        ),
      })),
    };
  });

  queryClient.setQueriesData(
    {
      queryKey: ["infStatuses", contentType],
      exact: false,
      predicate: (query) =>
        Array.isArray(query.queryKey) &&
        query.queryKey.length > 2 &&
        (query.queryKey[2] === oldStatus ||
          (!oldStatus && query.queryKey[2] !== statusObject.user_status)),
    },
    (oldData) => {
      if (!oldData) return oldData;

      return {
        ...oldData,
        pages: oldData.pages.map((page) => ({
          ...page,
          items: page.items.filter((item) => item.content_id !== contentId),
        })),
      };
    }
  );

  queryClient.invalidateQueries({
    queryKey: ["infStatuses", contentType, statusObject.user_status],
  });
  statusObject.user_status === "finished" &&
    queryClient.invalidateQueries({
      queryKey: ["infJournal", contentType],
    });
};

export const deleteContentStatusInCache = ({
  queryClient,
  contentId,
  contentType,
}) => {
  queryClient.removeQueries({
    queryKey: ["status", contentId],
    exact: "true",
  });
  queryClient.removeQueries({
    queryKey: ["journal", contentId],
    exact: "true",
  });

  queryClient.setQueriesData(
    { queryKey: ["infStatuses", contentType], exact: false },
    (oldData) => {
      if (!oldData) return oldData;

      return {
        ...oldData,
        pages: oldData.pages.map((page) => ({
          ...page,
          items: page.items.filter((item) => item.content_id !== contentId),
        })),
      };
    }
  );

  queryClient.setQueriesData(
    { queryKey: ["infJournal", contentType], exact: false },
    (oldData) => {
      if (!oldData) return oldData;

      return {
        ...oldData,
        pages: oldData.pages.map((page) => ({
          ...page,
          items: page.items.filter((item) => item.content_id !== contentId),
        })),
      };
    }
  );

  queryClient.setQueryData(["infFeed", contentType], (oldData) => {
    if (!oldData) return oldData; // if the activity doesn't exist, do nothing

    return {
      ...oldData,
      pages: oldData.pages.map((page) => ({
        ...page,
        items: page.items.map((item) =>
          item.content_id === contentId
            ? { ...item, user_status: "none" }
            : item
        ),
      })),
    };
  });

  queryClient.setQueryData(["infFeed", "all"], (oldData) => {
    if (!oldData) return oldData; // if the activity doesn't exist, do nothing

    return {
      ...oldData,
      pages: oldData.pages.map((page) => ({
        ...page,
        items: page.items.map((item) =>
          item.content_id === contentId
            ? { ...item, user_status: "none" }
            : item
        ),
      })),
    };
  });
};

export const updateEntryInCache = ({
  queryClient,
  entryObject,
  contentType,
}) => {
  queryClient.setQueryData(["journal", entryObject.content_id], (oldData) => {
    if (!oldData) return oldData;

    return oldData.map((entry) =>
      entry.journal_id === entryObject.journal_id ? entryObject : entry
    );
  });

  // To ensure status objects reorder with most recent at top when editing entry
  entryObject.status !== "finished" &&
    queryClient.invalidateQueries({
      queryKey: ["infStatuses", contentType, entryObject.status],
    });
  entryObject.status === "finished" &&
    queryClient.invalidateQueries({
      queryKey: ["infJournal", contentType],
    });
};

export const addEntryToCache = ({ queryClient, entryObject, contentType }) => {
  queryClient.setQueryData(["journal", entryObject.content_id], (oldData) => {
    return oldData ? [entryObject, ...oldData] : [entryObject];
  });

  entryObject.status !== "finished" &&
    queryClient.invalidateQueries({
      queryKey: ["infStatuses", contentType, entryObject.status],
    });
  entryObject.status === "finished" &&
    queryClient.invalidateQueries({
      queryKey: ["infJournal", contentType],
    });
};

export const deleteEntryFromCache = ({
  queryClient,
  contentId,
  contentType,
  deletedEntryId,
  deletedEntryStatus,
}) => {
  queryClient.setQueryData(["journal", contentId], (oldData) => {
    if (!oldData) return oldData;
    return oldData.filter((entry) => entry.journal_id !== deletedEntryId);
  });

  deletedEntryStatus === "finished" &&
    console.log(`attempting to invalidate query 'infJournal ${contentType}'`);
  queryClient.invalidateQueries({
    queryKey: ["infJournal", contentType],
  });
};

export const updateFollowStatusInCache = ({
  queryClient,
  username,
  followStatus,
}) => {
  const currentUser = queryClient.getQueryData(["currentUser"])?.userDetails;
  if (!currentUser) return;

  const currentUserData = {
    familyName: currentUser.familyName,
    givenName: currentUser.givenName,
    id: currentUser.id,
    picture: currentUser.picture,
    username: currentUser.username,
  };

  queryClient.setQueryData(["profile", username], (oldData) => {
    if (!oldData) return oldData; // Do nothing if target profile not present in the cache

    return {
      ...oldData,
      isFollowing: followStatus,
      followers: followStatus
        ? [currentUserData, ...(oldData.followers || [])]
        : oldData.followers.filter(
            (user) => user.username !== currentUser.username
          ),
    };
  });
};

export const updateListCache = ({
  queryClient,
  isDefault,
  listType,
  username,
  contentType,
}) => {
  if (isDefault) {
    queryClient.invalidateQueries({
      queryKey: ["defaultList", listType, username],
      exact: true,
    });
  } else {
    queryClient.invalidateQueries({
      queryKey: ["infCollections", contentType],
    });
    queryClient.invalidateQueries({
      queryKey: ["infCollections", "all"],
    });
  }
};
