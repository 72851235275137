import { useQuery } from "@tanstack/react-query";
import useApi from "./useApi";

export function useContentMetadata(contentType, id) {
  const { fetchMetadata } = useApi();
  const queryFn = fetchMetadata[contentType];

  return useQuery({
    queryKey: [contentType, "metadata", id],
    queryFn: () => queryFn(id),
    enabled: !!queryFn && !!id,
    staleTime: Infinity,
    cacheTime: Infinity,
  });
}

export function useBookMetadata(olWid) {
  const { fetchBookMetaData } = useApi();

  return useQuery({
    queryKey: ["book", "metaData", olWid],
    queryFn: () => fetchBookMetaData(olWid),
    enabled: !!olWid,
    staleTime: Infinity,
    cacheTime: Infinity,
  });
}
