import React, { useState, useEffect } from "react";
import { Card, Dialog, DialogContent, Grid } from "@mui/material";

import SearchBar from "../../components/SearchBar.js"; // Make sure to import your SearchBar component
import AddTile from "../../components/AddTile.js";
import PosterTile from "../../components/PosterTile.js";
import SortableItem from "../../components/SortableItem.js";
import { useQueryClient, useMutation } from "@tanstack/react-query";
import useApi from "../../hooks/useApi.js";
import RefreshIcon from "@mui/icons-material/Refresh";
import RemoveIcon from "@mui/icons-material/Remove";
import DragIndicatorIcon from "@mui/icons-material/DragIndicator";
// import MovieIcon from "@mui/icons-material/Movie";
import {
  DndContext,
  closestCenter,
  KeyboardSensor,
  PointerSensor,
  useSensor,
  useSensors,
  TouchSensor,
  DragOverlay,
} from "@dnd-kit/core";
import {
  arrayMove,
  rectSortingStrategy,
  SortableContext,
  sortableKeyboardCoordinates,
} from "@dnd-kit/sortable";
import CollectionMetaDataTile from "./Metadata.js";
import LibraryModal from "../track/LibraryModal.js";
import { toast } from "react-toastify";
import { useDebounce } from "../../hooks/useDebounce.js";
import { useAuth } from "../../context/authContext.js";
import { updateListCache } from "../../utils/cacheUtils.js";

const Collection = ({
  list,
  backgroundColor,
  onDelete,
  display = "modal",
  onClose,
  fullScreen,
  readOnly,
}) => {
  const {
    addItemToList,
    removeItemFromList,
    replaceItemInList,
    reorderItemsInList,
  } = useApi();
  const { user } = useAuth();
  const queryClient = useQueryClient();
  const { list_id, size, is_default, list_type, content_type } = list;
  const [openSearchDialog, setOpenSearchDialog] = useState(false);
  const [itemToReplace, setItemToReplace] = useState(null);
  const [action, setAction] = useState("");
  const [draggableId, setDraggableId] = useState(null);
  const [libraryModalId, setLibraryModalId] = useState(null);
  const [isPortrait, setIsPortrait] = useState(
    display === "inline" ? true : false
  ); // for collection metadata component

  // const tileWidth = { xs: 100, md: 170 };
  const maxTileWidth = 200;
  // const minTileWidth = 150;

  const [editedList, setEditedList] = useState(list);
  const numberOfItems = size ? size : list.items.length ? list.items.length : 1;

  useEffect(() => {
    // Update state to use the latest data whenever list changes
    setEditedList(list);
  }, [list, numberOfItems, display]);

  const updateEditedList = (name, value) => {
    setEditedList((prevList) => ({
      ...prevList,
      [name]: typeof value === "function" ? value(prevList[name]) : value,
    }));
  };

  const handleOpenSearchDialog = (item, action) => {
    setOpenSearchDialog(true);
    setItemToReplace(item);
    setAction(action);
  };

  const handleCloseSearchDialog = () => {
    setOpenSearchDialog(false);
    setItemToReplace(null);
    setAction("");
  };

  const addItemMutation = useMutation({
    mutationFn: (params) => addItemToList(params),
    onSuccess: () => {
      updateListCache({
        queryClient,
        isDefault: is_default,
        listType: list_type,
        username: user.username,
        contentType: content_type,
      });
    },
    onError: (error) => {
      toast.error(error.response?.data?.message);
    },
  });

  const removeItemMutation = useMutation({
    mutationFn: (params) => removeItemFromList(params),
    onSuccess: () => {
      updateListCache({
        queryClient,
        isDefault: is_default,
        listType: list_type,
        username: user.username,
        contentType: content_type,
      });
    },
    onError: (error) => {
      toast.error(error.response?.data?.message);
    },
  });

  const replaceItemMutation = useMutation({
    mutationFn: (params) => replaceItemInList(params),
    onSuccess: () => {
      updateListCache({
        queryClient,
        isDefault: is_default,
        listType: list_type,
        username: user.username,
        contentType: content_type,
      });
    },
    onError: (error) => {
      toast.error(error.response?.data?.message);
    },
  });

  const reorderListMutation = useMutation({
    mutationFn: (params) => reorderItemsInList(params),
    onSuccess: () => {
      updateListCache({
        queryClient,
        isDefault: is_default,
        listType: list_type,
        username: user.username,
        contentType: content_type,
      });
    },
    onError: (error) => {
      toast.error(error.response?.data?.message);
    },
  });

  const debouncedReorderListMutation = useDebounce(async (newItems) => {
    await reorderListMutation.mutateAsync({ listId: list_id, items: newItems });
  }, 5000); // Debounce with a 5-second delay

  useEffect(() => {
    return () => {
      // Execute pending changes on dismount if collection is closed before debounce delay
      debouncedReorderListMutation.flush();
    };
  }, [debouncedReorderListMutation]);

  const handleAdd = (contentId) => {
    addItemMutation.mutate({
      listId: list_id,
      contentId,
    });
    handleCloseSearchDialog();
  };

  const handleReplace = async (newContentId) => {
    await debouncedReorderListMutation.flush();
    replaceItemMutation.mutate({
      listId: list_id,
      oldContentId: itemToReplace.content_id,
      newContentId,
    });
    handleCloseSearchDialog();
  };

  const handleRemove = async (contentId) => {
    await debouncedReorderListMutation.flush(); // ensure any pending reorders are applied before
    removeItemMutation.mutate({
      listId: list_id,
      contentId,
    });
  };

  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(TouchSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    })
  );

  function handleDragStart(event) {
    const { active } = event;
    setDraggableId(active.id);
  }

  function handleDragEnd(event) {
    const { active, over } = event;

    if (over && active.id !== over.id) {
      setDraggableId(null);
      updateEditedList("items", (currentItems) => {
        const oldIndex = currentItems.findIndex(
          (item) => item.content_id === active.id
        );
        const newIndex = currentItems.findIndex(
          (item) => item.content_id === over.id
        );

        const newItems = arrayMove(currentItems, oldIndex, newIndex).map(
          (item, index) => ({
            ...item,
            position: index + 1,
          })
        );

        debouncedReorderListMutation(newItems); // ensures API call is only made once, x seconds after the last reorder
        return newItems;
      });
    }
  }

  const handleTileClick = (item) => {
    if (!user) {
      toast.info("Log in or sign up to view this content (it's free!)");
    } else {
      setLibraryModalId(item.content_id);
    }
  };

  const gridValLg =
    isPortrait && numberOfItems === 1
      ? 6
      : isPortrait && numberOfItems === 2
      ? 4
      : isPortrait && numberOfItems === 3 // tick
      ? 3
      : isPortrait && numberOfItems === 4 // tick
      ? 2.4
      : isPortrait && numberOfItems >= 5 // tick
      ? 2
      : !isPortrait && numberOfItems === 2
      ? 6
      : !isPortrait && numberOfItems === 3 // tick
      ? 4
      : !isPortrait && numberOfItems === 4 // tick
      ? 3
      : !isPortrait && numberOfItems === 5 // tick
      ? 2.4
      : !isPortrait && numberOfItems >= 6 // tick
      ? 2
      : numberOfItems === 1
      ? 6
      : undefined;

  const gridValMd =
    isPortrait && numberOfItems === 1
      ? 6
      : isPortrait && numberOfItems === 2
      ? 4
      : isPortrait && numberOfItems === 3 // tick
      ? 3
      : isPortrait && numberOfItems === 4 // tick
      ? 4
      : isPortrait && numberOfItems >= 5 // tick
      ? 2
      : !isPortrait && numberOfItems === 2
      ? 6
      : !isPortrait && numberOfItems === 3 // tick
      ? 4
      : !isPortrait && numberOfItems === 4 // tick
      ? 3
      : !isPortrait && numberOfItems === 5 // tick
      ? 4
      : !isPortrait && numberOfItems >= 6 // tick
      ? 3
      : numberOfItems === 1
      ? 6
      : undefined;

  const gridValSm =
    isPortrait && numberOfItems === 1
      ? 6
      : isPortrait && numberOfItems === 2
      ? 4
      : isPortrait && numberOfItems === 3
      ? 3
      : isPortrait && numberOfItems === 4
      ? 3
      : isPortrait && numberOfItems >= 5 // tick
      ? 2
      : !isPortrait && numberOfItems === 2
      ? 6
      : !isPortrait && numberOfItems === 3
      ? 4
      : !isPortrait && numberOfItems === 4
      ? 6
      : !isPortrait && numberOfItems === 5 // tick
      ? 4
      : !isPortrait && numberOfItems >= 6 // tick
      ? 4
      : numberOfItems === 1
      ? 6
      : undefined;

  const gridValXs =
    isPortrait && numberOfItems === 1
      ? 6
      : isPortrait && numberOfItems === 2
      ? 6
      : isPortrait && numberOfItems === 3
      ? 6
      : isPortrait && numberOfItems === 4
      ? 6
      : isPortrait && numberOfItems >= 5
      ? 6
      : !isPortrait && numberOfItems === 2
      ? 4
      : !isPortrait && numberOfItems === 3
      ? 4
      : !isPortrait && numberOfItems === 4
      ? 4
      : !isPortrait && numberOfItems === 5
      ? 4
      : !isPortrait && numberOfItems >= 6
      ? 4
      : numberOfItems === 1
      ? 4
      : undefined;

  const maxWidthLg = (12 / gridValLg) * maxTileWidth;
  const maxWidthMd = (12 / gridValMd) * maxTileWidth;
  const maxWidthSm = (12 / gridValSm) * maxTileWidth;
  // const maxWidthXs = (12 / gridValXs) * maxTileWidth; // not currently being used

  return (
    <>
      <Card
        elevation={0}
        sx={{
          p: 1.5,
          backgroundColor: backgroundColor,
          // width: numberOfItems === 1 && !isPortrait ? "100%" : "100%",
          width: "100%",
          maxWidth: { sm: maxWidthSm, md: maxWidthMd, lg: maxWidthLg },
          minHeight:
            numberOfItems === 1 && !fullScreen && !isPortrait
              ? "334.5px"
              : undefined,
          position: "relative",
          display: "flex",
          flexDirection: "column",
          flexGrow: 1,
        }}
      >
        <Grid
          container
          spacing={
            display === "modal"
              ? 2
              : list.size === 1
              ? 0.5
              : list.size === 5
              ? 2.5
              : 2
          }
          justifyContent={numberOfItems === 1 && !fullScreen && "center"}
          // spacing={2}
        >
          <Grid
            item
            xs={!isPortrait ? 12 : gridValXs}
            sm={!isPortrait ? 12 : gridValSm}
            md={!isPortrait ? 12 : gridValMd}
            lg={!isPortrait ? 12 : gridValLg}
          >
            <CollectionMetaDataTile
              editedList={editedList}
              updateEditedList={updateEditedList}
              setEditedList={setEditedList}
              originalList={list}
              onDelete={onDelete}
              onAdd={() => handleOpenSearchDialog(null, "add")}
              isPortrait={isPortrait}
              setIsPortrait={setIsPortrait}
              onCollectionClose={onClose}
              fullScreen={fullScreen}
            />
          </Grid>

          <DndContext
            sensors={sensors}
            collisionDetection={closestCenter}
            onDragStart={handleDragStart}
            onDragEnd={handleDragEnd}
          >
            <SortableContext
              items={editedList.items.map((item) => item.content_id)}
              strategy={rectSortingStrategy}
            >
              {editedList.items.map((item) => {
                return (
                  <Grid
                    key={item.content_id}
                    item
                    xs={gridValXs}
                    sm={gridValSm}
                    md={gridValMd}
                    lg={gridValLg}
                  >
                    <SortableItem key={item.content_id} id={item.content_id}>
                      {({ attributes, listeners }) => (
                        <PosterTile
                          contentId={item.content_id}
                          onClick={() => handleTileClick(item)}
                          position={
                            editedList.is_ranked ? item.position : undefined
                          }
                          secondaryActions={
                            !readOnly
                              ? [
                                  ...(editedList.items.length > 1
                                    ? [
                                        {
                                          label: "Drag to reorder",
                                          value: "reorder",
                                          icon: (
                                            <DragIndicatorIcon
                                              {...attributes}
                                              {...listeners}
                                              sx={{
                                                touchAction: "none",
                                                cursor: "grab",
                                              }}
                                            />
                                          ),
                                        },
                                      ]
                                    : []),
                                  {
                                    label: "Replace",
                                    value: "replace",
                                    icon: <RefreshIcon />,
                                    onClick: (event) => {
                                      event.stopPropagation();
                                      handleOpenSearchDialog(item, "replace");
                                    },
                                  },
                                  ...(editedList.size === null
                                    ? [
                                        {
                                          label: "Remove",
                                          value: "remove",
                                          icon: <RemoveIcon />,
                                          onClick: (event) => {
                                            event.stopPropagation();
                                            handleRemove(item.content_id);
                                          },
                                        },
                                      ]
                                    : []),
                                ]
                              : []
                          }
                        />
                      )}
                    </SortableItem>
                  </Grid>
                );
              })}
            </SortableContext>

            <DragOverlay>
              {draggableId ? <PosterTile contentId={draggableId} /> : null}
            </DragOverlay>
          </DndContext>

          {size &&
            Array.from(
              { length: size - editedList.items.length },
              (_, index) => (
                <Grid
                  item
                  key={index}
                  xs={gridValXs}
                  sm={gridValSm}
                  md={gridValMd}
                  lg={gridValLg}
                  // sx={{ border: "1px solid red" }}
                >
                  <AddTile
                    onClick={() => handleOpenSearchDialog(null, "add")}
                    profile={display === "inline"}
                    readOnly={readOnly}
                  />
                </Grid>
              )
            )}

          {editedList.items.length === 0 && !size && (
            <Grid
              item
              xs={gridValXs}
              sm={gridValSm}
              md={gridValMd}
              lg={gridValLg}
              // sx={{ border: "1px solid red" }}
            >
              <AddTile
                onClick={() => handleOpenSearchDialog(null, "add")}
                profile={display === "inline"}
                readOnly={readOnly}
              />
            </Grid>
          )}

          {fullScreen &&
            [1, 2].includes(editedList.items.length) &&
            Array.from({ length: 3 - editedList.items.length }).map(
              (_, index) => (
                <Grid
                  key={`invisible-add-tile-${index}`}
                  item
                  xs={gridValXs}
                  sx={{
                    visibility: "hidden", // Makes it invisible but reserves space
                  }}
                >
                  <AddTile profile={display === "inline"} readOnly={readOnly} />
                </Grid>
              )
            )}
        </Grid>
      </Card>

      <Dialog
        open={openSearchDialog}
        onClose={handleCloseSearchDialog}
        fullWidth
        maxWidth="sm"
        sx={{
          "& .MuiDialog-container": {
            alignItems: "flex-start",
            paddingTop: "25vh",
          },
        }}
        disableRestoreFocus={process.env.NODE_ENV === "development"}
      >
        <DialogContent
          sx={{
            border: "none",
            display: "flex",
            flexDirection: "column",
            gap: 1,
          }}
        >
          <SearchBar
            contentType={content_type}
            onSelect={action === "replace" ? handleReplace : handleAdd}
            enableQuickAdd={false}
            title={
              action === "replace" ? (
                <>
                  Replace <strong>{itemToReplace.title}</strong> with...
                </>
              ) : (
                <>
                  Add {content_type} to <strong>{list.title}</strong>
                </>
              )
            }
          />
        </DialogContent>
      </Dialog>

      {!!libraryModalId && (
        <LibraryModal
          open={!!libraryModalId}
          contentType={content_type}
          contentId={libraryModalId}
          onClose={() => setLibraryModalId(null)}
        />
      )}
    </>
  );
};

export default Collection;
