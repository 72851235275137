import React from "react";
import {
  Stack,
  Chip,
  Card,
  CardContent,
  Typography,
  CardActionArea,
  Rating,
} from "@mui/material";
import { formatDate, getYear, reactionOptions } from "../../utils/utils.js";

function JournalList({ item, onClick }) {
  return (
    <Card onClick={onClick} elevation={0}>
      <CardActionArea>
        <CardContent
          sx={{
            display: "flex",
            justifyContent: "space-between",
            p: "0px 4px",
          }}
        >
          <Stack
            direction="row"
            spacing={2}
            justifyContent={"flex-start"}
            alignItems={"center"}
          >
            <Typography
              variant="h6"
              sx={{
                fontWeight: "bold",
                maxWidth: {
                  xs: "200px",
                  sm: "350px",
                },
                whiteSpace: "nowrap",
                overflow: "hidden",
                textOverflow: "ellipsis",
              }}
            >
              {" "}
              {item?.content_title}{" "}
            </Typography>
            <Typography
              variant="subtitle1"
              color="text.secondary"
              sx={{
                fontStyle: "italic",
                display: { xs: "none", md: "inline-flex" },
              }}
            >
              {" "}
              {getYear(item?.content_date)}{" "}
            </Typography>
          </Stack>
          <Stack
            direction="row"
            spacing={1}
            justifyContent={"flex-end"}
            alignItems="center"
          >
            <Chip
              label={item?.date ? formatDate(item?.date) : "Undated"}
              size="small"
              sx={{
                backgroundColor: "transparent",
                color: "text.secondary",
                display: { xs: "none", md: "inline-flex" },
              }}
            />

            {item?.reactions &&
              item.reactions.slice(0, 4).map((reaction, index) => {
                const option = reactionOptions.find(
                  (option) => option.label === reaction
                );
                return (
                  <Chip
                    key={index}
                    icon={
                      option.icon || (
                        <span role="img" aria-label={option.label}>
                          {option.emoji}
                        </span>
                      )
                    }
                    label={reaction}
                    size="small"
                    sx={{
                      mr: 1,
                      display: { xs: "none", lg: "inline-flex" },
                    }}
                  />
                );
              })}

            {item?.reactions && item.reactions.length > 4 && (
              <Chip
                label={`+${item.reactions.length - 4}`}
                size="small"
                sx={{
                  mr: 1,
                  display: { xs: "none", lg: "inline-flex" },
                }}
              />
            )}

            <Rating value={item?.rating} readOnly precision={0.5} />
          </Stack>
        </CardContent>
      </CardActionArea>
    </Card>
  );
}

export default JournalList;
