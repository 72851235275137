import {
  Autocomplete,
  Avatar,
  Box,
  Button,
  IconButton,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import useApi from "../../hooks/useApi";
import { useNavigate } from "react-router-dom";
import { useDebounce } from "../../hooks/useDebounce.js";
import CloseIcon from "@mui/icons-material/Close";
import CheckIcon from "@mui/icons-material/Check";
import AddIcon from "@mui/icons-material/Add";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { updateFollowStatusInCache } from "../../utils/cacheUtils.js";
import { toast } from "react-toastify";

export default function UserSearch({ fullscreen, onClose }) {
  const { fetchUserSearch, followUser, unfollowUser } = useApi();
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const [input, setInput] = useState("");
  const [options, setOptions] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const debouncedSearch = useDebounce(async (query) => {
    try {
      setIsLoading(true);
      const results = await fetchUserSearch(query);
      if (results.length > 0) {
        setOptions(results);
      } else {
        setOptions([{ noResults: true, label: "No results found" }]);
      }
    } catch (error) {
      console.error("Failed to fetch search results");
    } finally {
      setIsLoading(false);
    }
  }, 300);

  useEffect(() => {
    return () => {
      debouncedSearch.cancel();
    };
  }, [debouncedSearch]);

  const handleInputChange = async (event, value) => {
    setInput(value);
    if (value.trim().length > 0) {
      debouncedSearch(value);
    } else {
      setOptions([]);
      debouncedSearch.cancel();
    }
  };

  const handleSelect = (event, value) => {
    if (value) {
      navigate(`/app/explore/user/${value.username}`);
    }
  };

  function stringToColor(string) {
    let hash = 0;
    let i;

    /* eslint-disable no-bitwise */
    for (i = 0; i < string.length; i += 1) {
      hash = string.charCodeAt(i) + ((hash << 5) - hash);
    }

    let color = "#";

    for (i = 0; i < 3; i += 1) {
      const value = (hash >> (i * 8)) & 0xff;
      color += `00${value.toString(16)}`.slice(-2);
    }
    /* eslint-enable no-bitwise */

    return color;
  }

  function stringAvatar(name) {
    if (!name) return { children: "?" }; // Default fallback if no name is available

    const nameParts = name.trim().split(" ").filter(Boolean); // Ensure no empty parts

    return {
      sx: {
        bgcolor: stringToColor(name),
      },
      children:
        nameParts.length > 1
          ? `${nameParts[0][0]}${nameParts[1][0].toUpperCase()}` // First letters of first & last name
          : `${nameParts[0][0].toUpperCase()}`, // Only first letter if single word
    };
  }

  const followMutation = useMutation({
    mutationFn: (option) => followUser(option.username),
    onSuccess: (_, option) => {
      option.isFollowing = true;
      updateFollowStatusInCache({
        queryClient,
        username: option.username,
        followStatus: true,
      });
    },
    onError: (error) => {
      toast.error(error.response?.data?.message);
    },
  });

  const unfollowMutation = useMutation({
    mutationFn: (option) => unfollowUser(option.username),
    onSuccess: (_, option) => {
      option.isFollowing = false;
      updateFollowStatusInCache({
        queryClient,
        username: option.username,
        followStatus: false,
      });
    },
    onError: (error) => {
      toast.error(error.response?.data?.message);
    },
  });

  return (
    <Box>
      <Stack
        direction="row"
        alignItems="center"
        justifyContent="space-between"
        sx={{ mb: 1 }}
      >
        <Typography variant="body2">Search for profiles</Typography>
        <Box display="flex" alignItems="center">
          {fullscreen && onClose && (
            <IconButton onClick={onClose} size="small" sx={{ ml: 1 }}>
              {" "}
              <CloseIcon />{" "}
            </IconButton>
          )}
        </Box>
      </Stack>

      <Autocomplete
        freeSolo
        fullWidth
        options={options}
        clearOnEscape
        onInputChange={handleInputChange}
        onChange={handleSelect}
        inputValue={input}
        loading={isLoading}
        loadingText="Searching..."
        filterOptions={(x) => (x.length === 0 ? [] : x)}
        getOptionLabel={(option) => {
          if (option.noResults) {
            return option.label;
          }
          return option.username || "";
        }}
        renderOption={(props, option) => {
          if (option.noResults) {
            return (
              <li {...props} style={{ pointerEvents: "none" }}>
                <Typography fontWeight="bold">{option.label}</Typography>
              </li>
            );
          }
          return (
            <li {...props} key={option.username}>
              <Stack
                direction="row"
                justifyContent="space-between"
                alignItems="center"
                width="100%"
              >
                <Stack direction="row" alignItems="center" spacing={1}>
                  <Avatar
                    {...stringAvatar(option.fullName || option.username)}
                  />
                  {!fullscreen && <Typography>{option.fullName}</Typography>}
                  <Typography variant={fullscreen ? "body2" : "body1"}>
                    <i>@{option.username}</i>
                  </Typography>
                </Stack>
                {option.isFollowing ? (
                  <Button
                    variant="outlined"
                    size="small"
                    startIcon={<CheckIcon />}
                    disabled={unfollowMutation.isPending}
                    onClick={(event) => {
                      event.stopPropagation();
                      unfollowMutation.mutate(option);
                    }}
                    sx={
                      fullscreen
                        ? {
                            minWidth: 0,
                            padding: 0,
                            height: 28,
                            width: 28,
                            "& .MuiButton-startIcon": {
                              m: 0,
                              p: 1,
                            },
                          }
                        : {}
                    }
                  >
                    {fullscreen ? "" : "Following"}
                  </Button>
                ) : (
                  <Button
                    variant="outlined"
                    size="small"
                    startIcon={<AddIcon />}
                    disabled={followMutation.isPending}
                    onClick={(event) => {
                      event.stopPropagation();
                      followMutation.mutate(option);
                    }}
                    sx={
                      fullscreen
                        ? {
                            minWidth: 0,
                            padding: 0,
                            height: 28,
                            width: 28,
                            "& .MuiButton-startIcon": {
                              m: 0,
                              p: 1,
                            },
                          }
                        : {}
                    }
                  >
                    {fullscreen ? "" : "Follow"}
                  </Button>
                )}
              </Stack>
            </li>
          );
        }}
        renderInput={(params) => (
          <TextField
            {...params}
            autoFocus
            hiddenLabel={true}
            size="small"
            variant="filled"
            InputProps={{
              ...params.InputProps,
              disableUnderline: true,
            }}
          />
        )}
      />
    </Box>
  );
}
