import React, { useState } from "react";
import LibraryModal from "../features/track/LibraryModal.js";
import { Grid, Box, LinearProgress, Container } from "@mui/material";
import { motion } from "framer-motion";
// import Lottie from "lottie-react";
import useApi from "../hooks/useApi.js";
import InfiniteScroll from "react-infinite-scroll-component";
import LibraryHeader from "../features/track/LibraryHeader.js";
import { useInfiniteQuery } from "@tanstack/react-query";
import LibraryList from "../features/track/LibraryList.js";
import JournalList from "../features/track/JournalList.js";
import PosterTile from "../components/PosterTile.js";
// import arrowAnimation from "../assets/down-right-arrow.json";
import Loading from "../components/Loading.js";
import { useOutletContext } from "react-router-dom";
import EmptyLibrary from "../features/track/EmptyLibrary.js";
import ErrorPage from "./ErrorPage.js";

export default function Track() {
  const { toggleDrawer, drawerWidth, setIsFabAnimated } = useOutletContext();
  const { fetchStatuses, fetchJournal } = useApi();
  const [contentType, setContentType] = useState("movie");
  const [status, setStatus] = useState("finished");
  const [sortBy, setSortBy] = useState("watch_date");
  const [sortDescending, setSortDescending] = useState(true);
  const [view, setView] = useState("grid");
  const [filters, setFilters] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [activeItem, setActiveItem] = useState({
    contentId: undefined,
    entryId: undefined,
    contentType: undefined,
  });

  const handleViewChange = (event, newView) => {
    if (newView !== null) {
      setView(newView);
    }
  };

  const handleSortByChange = (event, newSortBy) => {
    if (newSortBy !== null) {
      setSortBy(newSortBy);
    }
  };

  const toggleSortDescending = () => {
    setSortDescending(!sortDescending);
  };

  const handleContentChange = (newValue) => {
    setContentType(newValue);
  };

  const handleStatusChange = (newValue) => {
    setStatus(newValue);
  };

  const handleFiltersChange = (newValue) => {
    setFilters(newValue);
  };

  const handleSearchTermChange = (newValue) => {
    setSearchTerm(newValue);
  };

  const statusesInfiniteQuery = useInfiniteQuery({
    queryKey: [
      "infStatuses",
      contentType,
      status,
      { sortDescending, filters, searchTerm },
    ],
    queryFn: ({ pageParam = 1 }) =>
      fetchStatuses({
        pageParam,
        perPage: 24,
        contentType,
        sortDescending,
        filters,
        searchTerm,
        status,
      }),
    getNextPageParam: (lastPage) => lastPage.nextPage,
    enabled: status !== "finished",
    staleTime: 24 * 60 * 60 * 1000, // 24 hours
    cacheTime: 7 * 24 * 60 * 60 * 1000, // 1 week
  });

  const movieStatuses = React.useMemo(
    () => statusesInfiniteQuery.data?.pages.flatMap((page) => page.items) || [],
    [statusesInfiniteQuery.data]
  );

  const journalInfiniteQuery = useInfiniteQuery({
    queryKey: [
      "infJournal",
      contentType,
      status,
      { sortBy, sortDescending, filters, searchTerm },
    ],
    queryFn: ({ pageParam = 1 }) =>
      fetchJournal({
        pageParam,
        perPage: 24,
        contentType,
        sortDescending,
        sortBy,
        filters,
        searchTerm,
        status,
      }),
    getNextPageParam: (lastPage) => lastPage.nextPage,
    enabled: status === "finished",
    staleTime: 24 * 60 * 60 * 1000, // 24 hours
    cacheTime: 7 * 24 * 60 * 60 * 1000, // 1 week
  });

  const movieJournal = React.useMemo(
    () => journalInfiniteQuery.data?.pages.flatMap((page) => page.items) || [],
    [journalInfiniteQuery.data]
  );

  React.useEffect(() => {
    const shouldAnimate =
      ((status === "finished" &&
        movieJournal.length === 0 &&
        !journalInfiniteQuery.isPending &&
        !journalInfiniteQuery.isError) ||
        (status !== "finished" &&
          status !== "abandoned" &&
          movieStatuses.length === 0 &&
          !statusesInfiniteQuery.isPending &&
          !statusesInfiniteQuery.isError)) &&
      filters.length === 0 &&
      searchTerm === "";

    setIsFabAnimated(shouldAnimate);
  }, [
    status,
    movieJournal.length,
    journalInfiniteQuery.isPending,
    journalInfiniteQuery.isError,
    movieStatuses.length,
    statusesInfiniteQuery.isPending,
    statusesInfiniteQuery.isError,
    filters.length,
    searchTerm,
    setIsFabAnimated,
  ]);

  if (status === "finished" && journalInfiniteQuery.isError)
    return <ErrorPage />;
  if (status !== "finished" && statusesInfiniteQuery.isError)
    return <ErrorPage />;

  console.log("active item = ", activeItem);

  return (
    <Container maxWidth="lg" sx={{ pb: 2 }}>
      <LibraryHeader
        content={contentType}
        onContentChange={handleContentChange}
        status={status}
        onStatusChange={handleStatusChange}
        view={view}
        onViewChange={handleViewChange}
        sortBy={sortBy}
        onSortByChange={handleSortByChange}
        sortDescending={sortDescending}
        toggleSortDescending={toggleSortDescending}
        toggleDrawer={toggleDrawer}
        activeFilters={filters}
        onActiveFiltersChange={handleFiltersChange}
        searchTerm={searchTerm}
        onSearchTermChange={handleSearchTermChange}
        drawerWidth={drawerWidth}
      />

      {status === "finished" && (
        <>
          {journalInfiniteQuery.isLoading && (
            <Box
              width="100%"
              height="75vh"
              display="flex"
              justifyContent="center"
              alignItems="center"
            >
              <Loading />
            </Box>
          )}

          {journalInfiniteQuery.isSuccess && movieJournal.length === 0 ? (
            <Grid
              item
              xs={12}
              sx={{ display: "flex", justifyContent: "center" }}
            >
              <EmptyLibrary
                filtersApplied={filters.length !== 0 || searchTerm !== ""}
                status={status}
              />
            </Grid>
          ) : (
            <Grid item xs={12}>
              <InfiniteScroll
                dataLength={movieJournal.length}
                next={journalInfiniteQuery.fetchNextPage}
                hasMore={!!journalInfiniteQuery.hasNextPage}
                loader={
                  <Box sx={{ pt: 2, pb: 2 }}>
                    <LinearProgress />
                  </Box>
                }
                style={{ overflow: "visible" }}
                // Overflow visbile required to ensure framer-motion scale animation applied to library cards displays properly
              >
                <Grid container spacing={view === "list" ? 1 : 2}>
                  {movieJournal.map((entry) => {
                    return (
                      <Grid
                        item
                        key={`journal-${entry.journal_id}`}
                        xs={view === "list" ? 12 : 4}
                        sm={view === "list" ? 12 : 3}
                        md={view === "list" ? 12 : 3}
                        lg={view === "list" ? 12 : 2}
                        xl={view === "list" ? 12 : 2}
                      >
                        <motion.div
                          layout="position"
                          whileHover={{
                            scale: 1.02,
                            transition: { duration: 0.2 },
                          }}
                        >
                          {view === "list" ? (
                            <JournalList
                              item={entry}
                              onClick={() =>
                                setActiveItem({
                                  contentId: entry.content_id,
                                  contentType: entry.content_type,
                                  entryId: entry.journal_id,
                                })
                              }
                            />
                          ) : (
                            <PosterTile
                              contentId={entry.content_id}
                              onClick={() =>
                                setActiveItem({
                                  contentId: entry.content_id,
                                  contentType: entry.content_type,
                                  entryId: entry.journal_id,
                                })
                              }
                            />
                          )}
                        </motion.div>
                      </Grid>
                    );
                  })}
                </Grid>
              </InfiniteScroll>
            </Grid>
          )}
        </>
      )}

      {status !== "finished" && (
        <>
          {statusesInfiniteQuery.isLoading && (
            <Box
              width="100%"
              height="75vh"
              display="flex"
              justifyContent="center"
              alignItems="center"
            >
              <Loading />
            </Box>
          )}

          {statusesInfiniteQuery.isSuccess &&
          status !== "finished" &&
          movieStatuses.length === 0 ? (
            <Grid
              item
              xs={12}
              sx={{ display: "flex", justifyContent: "center" }}
            >
              <EmptyLibrary
                filtersApplied={filters.length !== 0 || searchTerm !== ""}
                status={status}
              />
            </Grid>
          ) : (
            <Grid item xs={12}>
              <InfiniteScroll
                dataLength={movieStatuses.length}
                next={statusesInfiniteQuery.fetchNextPage}
                hasMore={!!statusesInfiniteQuery.hasNextPage}
                loader={
                  <Box sx={{ pt: 2, pb: 2 }}>
                    <LinearProgress />
                  </Box>
                }
                // scrollableTarget="scrollableDiv"
                style={{ overflow: "visible" }} // Required to ensure framer-motion scale animation applied to library cards displays properly
              >
                <Grid container spacing={view === "list" ? 1 : 2}>
                  {movieStatuses.map((item) => {
                    return (
                      <Grid
                        item
                        key={`status-${item.content_id}`}
                        xs={view === "list" ? 12 : 4}
                        sm={view === "list" ? 12 : 3}
                        md={view === "list" ? 12 : 3}
                        lg={view === "list" ? 12 : 2}
                        xl={view === "list" ? 12 : 2}
                      >
                        <motion.div
                          layout="position"
                          whileHover={{
                            scale: 1.02,
                            transition: { duration: 0.2 },
                          }}
                        >
                          {view === "list" ? (
                            <LibraryList
                              item={item}
                              onClick={() =>
                                setActiveItem({
                                  contentId: item.content_id,
                                  contentType: item.content_type,
                                })
                              }
                            />
                          ) : (
                            <PosterTile
                              contentId={item.content_id}
                              onClick={() =>
                                setActiveItem({
                                  contentId: item.content_id,
                                  contentType: item.content_type,
                                })
                              }
                            />
                          )}
                        </motion.div>
                      </Grid>
                    );
                  })}
                </Grid>
              </InfiniteScroll>
            </Grid>
          )}
        </>
      )}

      {activeItem.contentId && (
        <LibraryModal
          open={!!activeItem.contentId}
          contentType={activeItem.contentType}
          contentId={activeItem.contentId}
          entryId={activeItem.entryId}
          onClose={() =>
            setActiveItem({
              contentId: undefined,
              entryId: undefined,
              contentType: undefined,
            })
          }
        />
      )}

      {/* {((status === "finished" &&
        movieJournal.length === 0 &&
        !journalInfiniteQuery.isPending &&
        !journalInfiniteQuery.isError) ||
        (status !== "finished" &&
          status !== "abandoned" &&
          movieStatuses.length === 0 &&
          !statusesInfiniteQuery.isPending &&
          !statusesInfiniteQuery.isError)) &&
        filters.length === 0 &&
        searchTerm === "" && (
          <Box sx={{ position: "absolute", bottom: 30, right: 30 }}>
            <Lottie
              animationData={arrowAnimation}
              loop={true}
              style={{ width: 200, height: 200 }}
            />
          </Box>
        )} */}
    </Container>
  );
}
