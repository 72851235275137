import React, { useState } from "react";
import {
  Stack,
  Card,
  CardContent,
  IconButton,
  TextField,
  Tooltip,
  useTheme,
  Grid,
  Box,
  Typography,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import SaveIcon from "@mui/icons-material/Save";
import AddIcon from "@mui/icons-material/Add";
import DateSelectionButton from "../../components/DateSelectionButton";
import TagInput from "../../components/ChipInput";
import { toast } from "react-toastify";
import { motion } from "framer-motion";
import useApi from "../../hooks/useApi";
import dayjs from "dayjs";
import HoverRating from "../../components/HoverRating";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { getStatusOptions } from "../../utils/utils";
import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";
import {
  addEntryToCache,
  deleteEntryFromCache,
  updateEntryInCache,
} from "../../utils/cacheUtils";

export default function JournalEntryEditable({
  mode,
  contentType,
  contentId,
  existingEntry = {},
  onEdit,
  onAdd,
  onDelete,
  isMobile,
}) {
  const queryClient = useQueryClient();
  const { editEntry, addEntry, deleteEntry } = useApi();
  const theme = useTheme();
  const statusOptions = getStatusOptions(theme);
  const status = mode === "add" ? "finished" : existingEntry.status;
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);

  const today = dayjs().format("YYYY-MM-DD");

  const [isEdited, setIsEdited] = useState(false);
  const [editedEntry, setEditedEntry] = useState(
    mode === "add"
      ? {
          content_type: contentType,
          content_id: contentId,
          date: today,
          status: "finished", // this component is only opened in add mode when the status is set to finished
          rating: 0,
          reactions: [],
          notes: "",
        }
      : {
          content_type: contentType,
          content_id: existingEntry.content_id,
          date: existingEntry.date,
          status: existingEntry.status,
          rating: existingEntry.rating,
          reactions: existingEntry.reactions ? existingEntry.reactions : [],
          notes: existingEntry.notes ? existingEntry.notes : "",
        }
  );

  const editEntryMutation = useMutation({
    mutationFn: ({ entryId, editedEntry }) => editEntry(entryId, editedEntry),
    onSuccess: (data) => {
      updateEntryInCache({
        queryClient,
        contentType,
        entryObject: data.entryObject,
      });
      // toast.success(data.message);
      onEdit?.();
    },
    onError: (error) => {
      toast.error(error.response?.data?.message);
    },
  });

  const addEntryMutation = useMutation({
    mutationFn: addEntry,
    onSuccess: (data) => {
      addEntryToCache({
        queryClient,
        contentType,
        entryObject: data.entryObject,
      });
      // toast.success(data.message);
      onAdd?.();
    },
    onError: (error) => {
      toast.error(error.response?.data?.message);
    },
  });

  const deleteEntryMutation = useMutation({
    mutationFn: deleteEntry,
    onSuccess: (data, idBeingDeleted) => {
      deleteEntryFromCache({
        queryClient,
        contentId,
        contentType,
        deletedEntryId: idBeingDeleted,
        deletedEntryStatus: data.entryStatus,
      });
      // toast.success(data.message);
      setShowDeleteConfirmation(false);
      onDelete?.();
    },
    onError: (error) => {
      toast.error(error.response?.data?.message);
    },
  });

  const updateEditedEntry = (name, value) => {
    setEditedEntry({
      ...editedEntry,
      [name]: value,
    });
    setIsEdited(true);
  };

  const reactionsBlock =
    status !== "finished" ? null : (
      <Box sx={{ pt: !isMobile ? 1 : 0 }}>
        <TagInput
          selectedTags={editedEntry.reactions}
          onTagsChange={(newValue) => updateEditedEntry("reactions", newValue)}
        />
      </Box>
    );

  const notesBlock = (
    <TextField
      id="journal-notes-input"
      variant="standard"
      // label="Edit notes"
      placeholder="Add comment"
      fullWidth
      autoFocus={status !== "finished"}
      multiline
      maxRows={8}
      // size="small"
      value={editedEntry.notes || ""}
      onChange={(event) => updateEditedEntry("notes", event.target.value)}
      InputProps={{ disableUnderline: true }}
      sx={{
        ".MuiInputBase-root": {
          mt: status !== "finished" ? "3px" : undefined,
          // p: 0,
        },
        "& .MuiInputBase-input": {
          // Target the input element for typography
          fontSize: "0.875rem", // This matches the default fontSize of body2 in MUI v5
          // lineHeight: 1.43, // This matches the default lineHeight of body2 in MUI v5
        },
      }}
    />
  );

  return (
    <>
      <Card
        sx={{
          position: "relative",
        }}
      >
        <CardContent
          sx={{
            padding: 2,
            "&:last-child": {
              paddingBottom: 2,
            },
          }}
        >
          <Grid container spacing={1}>
            <Grid item xs={5} sm={3}>
              <Stack
                direction="row"
                alignItems="center"
                spacing={1}
                sx={{ p: "6px 0 6px" }}
              >
                {statusOptions[status].icon}
                <DateSelectionButton
                  value={editedEntry.date}
                  setValue={(newValue) => {
                    const newDate = newValue
                      ? newValue.format("YYYY-MM-DD")
                      : null;
                    updateEditedEntry("date", newDate);
                  }}
                  mode={mode}
                />
              </Stack>
            </Grid>

            <Grid item xs={7} sm={9}>
              {status === "finished" && (
                <HoverRating
                  value={editedEntry.rating}
                  onChange={(event, newValue) =>
                    updateEditedEntry("rating", newValue)
                  }
                  isMobile={isMobile}
                />
              )}

              {!isMobile && (
                <Stack direction="column" spacing={1}>
                  {reactionsBlock}
                  {notesBlock}
                </Stack>
              )}
            </Grid>

            {isMobile && (
              <Grid item xs={12}>
                <Stack direction="column" spacing={1}>
                  {reactionsBlock}
                  {notesBlock}
                </Stack>
              </Grid>
            )}

            <Grid item xs={12}>
              <Stack direction="column" spacing={1}>
                {mode === "add" && (
                  <Box display="flex" justifyContent={"flex-end"}>
                    <Tooltip title="Log">
                      <motion.div
                        animate={{ scale: isEdited ? [1, 1.1, 1] : 1 }}
                        transition={{
                          duration: 1.5,
                          repeat: Infinity,
                        }}
                      >
                        <IconButton
                          onClick={() => addEntryMutation.mutate(editedEntry)}
                          disabled={addEntryMutation.isPending || !isEdited}
                          sx={{
                            p: 0.5,
                            color: isEdited ? "primary.main" : "default",
                          }}
                        >
                          <AddIcon />
                        </IconButton>
                      </motion.div>
                    </Tooltip>
                  </Box>
                )}

                {mode === "edit" && (
                  <>
                    {showDeleteConfirmation ? (
                      <Stack
                        direction="row"
                        spacing={0.5}
                        alignItems="center"
                        display="flex"
                        justifyContent="flex-end"
                      >
                        <Typography
                          variant="body2"
                          color="text.secondary"
                          sx={{ pr: 1 }}
                        >
                          <i>
                            {status === "finished"
                              ? "Are you sure? This will remove it from your library"
                              : "Are you sure?"}
                          </i>
                        </Typography>
                        <IconButton
                          onClick={() => {
                            setShowDeleteConfirmation(false);
                          }}
                          color="error"
                          sx={{ p: 0.5 }}
                        >
                          <CloseIcon />
                        </IconButton>
                        <IconButton
                          onClick={() => {
                            deleteEntryMutation.mutate(
                              existingEntry.journal_id
                            );
                          }}
                          sx={{ p: 0.5 }}
                          color="success"
                        >
                          <CheckIcon />
                        </IconButton>
                      </Stack>
                    ) : (
                      <Stack
                        direction="row"
                        spacing={0.5}
                        display="flex"
                        justifyContent="flex-end"
                      >
                        <Tooltip title="Save">
                          <motion.div
                            animate={{ scale: isEdited ? [1, 1.1, 1] : 1 }}
                            transition={{
                              duration: 1.5,
                              repeat: Infinity,
                            }}
                          >
                            <IconButton
                              onClick={() =>
                                editEntryMutation.mutate({
                                  entryId: existingEntry.journal_id,
                                  editedEntry,
                                })
                              }
                              disabled={
                                editEntryMutation.isPending || !isEdited
                              }
                              sx={{
                                p: 0.5,
                                color: isEdited ? "primary.main" : "default",
                              }}
                            >
                              <SaveIcon />
                            </IconButton>
                          </motion.div>
                        </Tooltip>
                        <Tooltip title="Delete">
                          <IconButton
                            onClick={() => setShowDeleteConfirmation(true)}
                            sx={{ p: 0.5 }}
                          >
                            <DeleteIcon />
                          </IconButton>
                        </Tooltip>
                        <Tooltip title="Cancel edit">
                          <IconButton onClick={onEdit} sx={{ p: 0.5 }}>
                            <CloseIcon />
                          </IconButton>
                        </Tooltip>
                      </Stack>
                    )}
                  </>
                )}
              </Stack>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </>
  );
}
