import React from "react";
import ReactDOM from "react-dom/client";
import { RouterProvider } from "react-router-dom";
import { toast } from "react-toastify";
import * as Sentry from "@sentry/react";
import "react-toastify/dist/ReactToastify.css";
import {
  QueryClient,
  QueryClientProvider,
  QueryCache,
} from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import {
  ThemeContextProvider,
  useThemeContext,
} from "./context/themeContext.js";
import {
  createTheme,
  CssBaseline,
  GlobalStyles,
  responsiveFontSizes,
  ThemeProvider,
} from "@mui/material";
import router from "./routes/router.js";

if (process.env.NODE_ENV === "production") {
  Sentry.init({
    dsn: process.env.REACT_APP_SENTRY_DSN,
    integrations: [
      Sentry.browserTracingIntegration(),
      Sentry.replayIntegration(),
    ],
    tracesSampleRate: 0.2,
    tracePropagationTargets: ["https://curateapp.io"],
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0,
  });
}

const queryClient = new QueryClient({
  defaultOptions: { queries: { staleTime: 5 * 60 * 1000 } },
  queryCache: new QueryCache({
    onError: (error, query) => {
      if (error.message === "Network Error" || error.code === "ECONNREFUSED") {
        toast.error(
          `The Curate servers are currently down for maintenance. Please try again later.`
        );
        window.location.href = "/?redirect=false";
      } else {
        toast.error(
          `Something went wrong: ${
            error.response?.data?.message || error.message
          }`
        );
      }
    },
  }),
});

const root = ReactDOM.createRoot(document.getElementById("root"));

function Root() {
  const { mode } = useThemeContext();
  let theme = createTheme({
    palette: {
      mode,
      primary: {
        main: "#00d37e",
        contrastText: "#fff",
      },
      secondary: {
        main: mode === "dark" ? "#1D3639" : "#f0f0f0",
        contrastText: mode === "dark" ? "#fff" : "#122425",
      },
      tertiary: {
        main: "#b1a7ec",
        contrastText: "#fff",
      },
      background: {
        default: mode === "dark" ? "#122425" : "#fff",
        paper: mode === "dark" ? "#122425" : "#fff",
      },
      text: {
        primary: mode === "dark" ? "#fff" : "#122425",
        // secondary: "#555555", // Secondary text color
      },
      status: {
        queued: {
          main: "#b1a7ec",
          contrastText: "#fff",
        },
        started: {
          main: "#9ee7b9",
          contrastText: "#333",
        },
        abandoned: {
          main: "#f27c75",
          contrastText: "#fff",
        },
        finished: {
          main: "#00d37e",
          contrastText: "#fff",
        },
      },
    },
    components: {
      MuiButton: {
        styleOverrides: {
          root: {
            textTransform: "none", // Remove capitalisation
            boxShadow: "none",
          },
        },
      },
      MuiTooltip: {
        styleOverrides: {
          root: {
            maxWidth: "none", // remove the forced constraint entirely
            whiteSpace: "nowrap", // prevent wrapping on small screens
          },
        },
      },
    },
  });

  theme = responsiveFontSizes(theme);

  const inputGlobalStyles = (
    <GlobalStyles
      styles={{
        img: {
          WebkitTouchCallout: "none", // prevents context menu on IOS (but not Android)
          WebkitUserSelect: "none",
          MozUserSelect: "none",
          msUserSelect: "none",
          userSelect: "none",
        },
        ":root": {
          // Toast colours
          "--toastify-color-light": theme.palette.secondary.main,
          "--toastify-color-dark": theme.palette.secondary.main,
          "--toastify-text-color-light": theme.palette.text.primary,
          "--toastify-text-color-dark": theme.palette.text.primary,
          "--toastify-color-success": theme.palette.primary.main,
          "--toastify-color-warning": theme.palette.tertiary.main,
          "--toastify-color-error": theme.palette.status.abandoned.main,
          "--toastify-color-info": theme.palette.tertiary.main,
          "--toastify-color-progress-light": theme.palette.primary.main,
          "--toastify-color-progress-dark": theme.palette.primary.main,

          // Toast layout styles
          "--toastify-toast-bd-radius": `${theme.shape.borderRadius}px`,
          "--toastify-toast-shadow": "0px 4px 12px rgba(0, 0, 0, 0.2)",
          "--toastify-font-family": theme.typography.fontFamily,

          // Toast margin/padding
          "--toastify-toast-padding": "8px",
          "--toastify-toast-min-height": "0px",
        },
        "@media only screen and (max-width: 480px)": {
          ".Toastify__toast-container": {
            width: "80%", // Override default width of 100vw
            left: "auto", // Override default that sticks container to left edge of screen
          },
          ".Toastify__toast": {
            borderRadius: `${theme.shape.borderRadius}px`, // override default of zero
            marginBottom: "10px", // ensures gap between multiple toasts
          },
        },
        ".MuiSpeedDialAction-staticTooltipLabel": {
          // styles applied to the static tooltip label if tooltipOpen={true}
          whiteSpace: "nowrap",
        },
      }}
    />
  );

  return (
    <ThemeProvider theme={theme}>
      {inputGlobalStyles}
      <CssBaseline />
      <QueryClientProvider client={queryClient}>
        <RouterProvider router={router} />
        {process.env.NODE_ENV === "development" && <ReactQueryDevtools />}
      </QueryClientProvider>
    </ThemeProvider>
  );
}

root.render(
  <React.StrictMode>
    <ThemeContextProvider>
      <Root />
    </ThemeContextProvider>
  </React.StrictMode>
);
