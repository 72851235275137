import { useCallback } from "react";
import axios from "axios";
import { useThemeContext } from "../context/themeContext";
// import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
const API_BASE_URL = process.env.REACT_APP_BACKEND_URL;
const logFetches = process.env.NODE_ENV === "development";

function getCookie(name) {
  const value = `; ${document.cookie}`;
  const parts = value.split(`; ${name}=`);
  if (parts.length === 2) return parts.pop().split(";").shift();
}

export default function useApi() {
  const { setMode } = useThemeContext();
  // const navigate = useNavigate();

  const apiRequest = useCallback(async (url, options) => {
    const makeRequest = async () => {
      return axios(url, {
        ...options,
        headers: {
          "X-CSRF-TOKEN": getCookie("csrf_access_token"),
          ...options.headers,
        },
        withCredentials: true,
      });
    };

    try {
      let response = await makeRequest();
      return response;
    } catch (error) {
      if (error.response && error.response.status === 401) {
        try {
          console.log("Refreshing access token");
          await axios.post(
            `${API_BASE_URL}/token/refresh`,
            {},
            {
              withCredentials: true,
              headers: {
                "X-CSRF-TOKEN": getCookie("csrf_refresh_token"),
              },
            }
          );
          let retryResponse = await makeRequest();
          return retryResponse;
        } catch (refreshError) {
          throw refreshError;
        }
      }
      throw error;
    }
  }, []);

  const fetchMovieMetaData = useCallback(
    async (id) => {
      logFetches && console.log("fetchMovieMetaData fired");
      const response = await apiRequest(
        `${API_BASE_URL}/metadata/movie/${id}`,
        {
          method: "GET",
        }
      );
      return response.data;
    },
    [apiRequest]
  );

  const fetchBookMetaData = useCallback(
    async (id) => {
      logFetches && console.log("fetchBookMetaData fired");
      const response = await apiRequest(
        `${API_BASE_URL}/book/metadata?q=${id}`,
        {
          method: "GET",
        }
      );
      return response.data;
    },
    [apiRequest]
  );

  const fetchMetadata = {
    movie: fetchMovieMetaData,
    book: fetchBookMetaData,
  };

  const fetchContentAutocomplete = useCallback(
    async (searchInput, contentType) => {
      logFetches && console.log("fetchContentAutocomplete fired");
      const response = await apiRequest(
        `${API_BASE_URL}/content/autocomplete?q=${encodeURIComponent(
          searchInput
        )}&type=${encodeURIComponent(contentType)}`,
        {
          method: "GET",
        }
      );
      return response.data;
    },
    [apiRequest]
  );

  const fetchContentSearch = useCallback(
    async (searchInput, contentType) => {
      logFetches && console.log("fetchContentSearch fired");
      const response = await apiRequest(
        `${API_BASE_URL}/content/search?q=${encodeURIComponent(
          searchInput
        )}&type=${encodeURIComponent(contentType)}`,
        {
          method: "GET",
        }
      );
      return response.data;
    },
    [apiRequest]
  );

  const fetchJournal = useCallback(
    async ({
      pageParam = 1,
      perPage,
      contentType,
      sortBy,
      sortDescending,
      filters,
      searchTerm,
    }) => {
      logFetches && console.log("fetchJournal fired");
      const filterParams = filters
        ? filters.reduce((acc, filter) => {
            const filterKey = `${filter.type.toLowerCase()}_filter`;
            if (Array.isArray(filter.value)) {
              // Join array values for genres and decades into a comma-separated string
              acc[filterKey] = filter.value.join(",");
            } else {
              // Directly assign the value for rating
              acc[filterKey] = filter.value;
            }
            return acc;
          }, {})
        : {}; // If no filters are provided, set to empty object

      const response = await apiRequest(`${API_BASE_URL}/journal`, {
        method: "GET",
        params: {
          page: pageParam,
          per_page: perPage,
          content_type: contentType,
          sort_by: sortBy,
          sort_descending: sortDescending, // axios converts boolean parameter to string: "true" or "false"
          search_term: searchTerm,
          ...filterParams,
        },
      });

      return {
        items: response.data.items,
        nextPage: response.data.hasMore ? pageParam + 1 : undefined,
        hasMore: response.data.hasMore,
      };
    },
    [apiRequest]
  );

  const editEntry = useCallback(
    async (entryId, editedEntry) => {
      logFetches && console.log("editEntry fired");
      const response = await apiRequest(`${API_BASE_URL}/journal/${entryId}`, {
        method: "PUT",
        data: editedEntry,
      });
      return response.data;
    },
    [apiRequest]
  );

  const addEntry = useCallback(
    async (newEntry) => {
      logFetches && console.log("addEntry fired");
      const response = await apiRequest(`${API_BASE_URL}/journal`, {
        method: "POST",
        data: newEntry,
      });
      return response.data;
    },
    [apiRequest]
  );

  const deleteEntry = useCallback(
    async (entryId) => {
      logFetches && console.log(`deleteEntry fired for entryId ${entryId}`);
      const response = await apiRequest(`${API_BASE_URL}/journal/${entryId}`, {
        method: "DELETE",
      });
      return response.data;
    },
    [apiRequest]
  );

  const fetchJournalForMovie = useCallback(
    async (contentId) => {
      logFetches &&
        console.log(`fetchJournalForMovie fired for content id = ${contentId}`);
      const response = await apiRequest(
        `${API_BASE_URL}/journalforcontent/${contentId}`,
        {
          method: "GET",
        }
      );
      return response.data;
    },
    [apiRequest]
  );

  // Status endpoints

  const fetchStatuses = useCallback(
    async ({
      pageParam = 1,
      perPage,
      contentType,
      status,
      sortDescending,
      filters,
      searchTerm,
    }) => {
      logFetches && console.log(`fetchStatuses fired`);
      const filterParams = filters
        ? filters.reduce((acc, filter) => {
            const filterKey = `${filter.type.toLowerCase()}_filter`;
            if (Array.isArray(filter.value)) {
              // Join array values for genres and decades into a comma-separated string
              acc[filterKey] = filter.value.join(",");
            } else {
              // Directly assign the value for rating
              acc[filterKey] = filter.value;
            }
            return acc;
          }, {})
        : {}; // If no filters are provided, set to empty object

      const response = await apiRequest(`${API_BASE_URL}/statuses`, {
        method: "GET",
        params: {
          page: pageParam,
          per_page: perPage,
          status,
          content_type: contentType,
          sort_descending: sortDescending, // axios converts boolean parameter to string: "true" or "false"
          search_term: searchTerm,
          ...filterParams,
        },
      });

      return {
        items: response.data.items,
        nextPage: response.data.hasMore ? pageParam + 1 : undefined,
        hasMore: response.data.hasMore,
      };
    },
    [apiRequest]
  );

  const addStatus = useCallback(
    async (contentId, status, createJournalEntry) => {
      logFetches &&
        console.log(
          `addStatus fired with content ID ${contentId}, status ${status}`
        );
      const newStatus = {
        content_id: contentId,
        status: status,
        create_journal_entry: createJournalEntry,
      };
      const response = await apiRequest(`${API_BASE_URL}/statuses`, {
        method: "POST",
        data: newStatus,
      });
      return response.data;
    },
    [apiRequest]
  );

  const editStatus = useCallback(
    async ({ contentId, status, createJournalEntry }) => {
      logFetches && console.log("editStatus fired");
      const response = await apiRequest(`${API_BASE_URL}/status/${contentId}`, {
        method: "PUT",
        data: { status, createJournalEntry }, // status is a string, curly braces required to pass it as a JSON object
      });
      return response.data;
    },
    [apiRequest]
  );

  const deleteStatus = useCallback(
    async (contentId, confirm) => {
      logFetches && console.log(`deleteStatus fired`);
      const response = await apiRequest(`${API_BASE_URL}/status/${contentId}`, {
        method: "DELETE",
        data: { confirm },
      });
      return response.data;
    },
    [apiRequest]
  );

  const fetchStatus = useCallback(
    async (contentId) => {
      logFetches &&
        console.log(`fetchStatus fired for contend id = ${contentId}`);
      const response = await apiRequest(`${API_BASE_URL}/status/${contentId}`, {
        method: "GET",
      });
      return response.data;
    },
    [apiRequest]
  );

  const addContent = useCallback(
    async (contentType, identifier) => {
      try {
        logFetches &&
          console.log(
            `addContent fired with content type ${contentType} and identifier ${identifier}`
          );
        const response = await apiRequest(`${API_BASE_URL}/content/add`, {
          method: "POST",
          data: {
            content_type: contentType,
            identifier,
          },
        });
        return response.data;
      } catch (error) {
        throw error;
      }
    },
    [apiRequest]
  );

  const fetchCollections = useCallback(
    async ({
      listId = null,
      isDefault = true,
      sortDescending = true,
      sortBy = "updated",
      searchTerm = "",
      pageParam = 1,
      perPage = 5,
      contentType,
    } = {}) => {
      logFetches && console.log(`fetchCollections fired`);
      let endpoint = `${API_BASE_URL}/lists`; // Default endpoint for lists without specific ID
      if (listId) {
        endpoint = `${API_BASE_URL}/lists/${listId}`;
      } else {
        // Only append the query parameter if no listId is provided
        const params = new URLSearchParams();
        params.append("is_default", isDefault);
        params.append("sort_descending", sortDescending);
        params.append("sort_by", sortBy);
        params.append("search_term", searchTerm);
        params.append("page", pageParam);
        params.append("per_page", perPage);
        params.append("content_type", contentType);
        endpoint += `?${params.toString()}`;
      }
      const response = await apiRequest(endpoint, {
        method: "GET",
      });
      return {
        items: response.data.items,
        nextPage: response.data.hasMore ? pageParam + 1 : undefined,
        hasMore: response.data.hasMore,
      };
    },
    [apiRequest]
  );

  const fetchList = useCallback(
    async (listId) => {
      try {
        logFetches && console.log(`fetchList fired`);
        const response = await apiRequest(`${API_BASE_URL}/lists/${listId}`, {
          method: "GET",
        });
        return response.data;
      } catch (error) {
        console.error("fetchList error: ", error);
        throw error;
      }
    },
    [apiRequest]
  );

  const fetchDefaultList = useCallback(
    async (
      content_type,
      list_type,
      username = null,
      month = null,
      year = null
    ) => {
      logFetches && console.log(`fetchDefaultList fired`);
      const response = await apiRequest(`${API_BASE_URL}/defaultlist`, {
        method: "GET",
        params: {
          content_type,
          list_type,
          ...(username && { username }),
          ...(month && { month }),
          ...(year && { year }),
        },
      });
      return response.data;
    },
    [apiRequest]
  );

  const addItemToList = useCallback(
    async ({ listId, contentId }) => {
      logFetches && console.log(`addItemToList fired`);
      const response = await apiRequest(`${API_BASE_URL}/lists/${listId}`, {
        method: "POST",
        data: { contentId },
      });
      return response.data;
    },
    [apiRequest]
  );

  const removeItemFromList = useCallback(
    async ({ listId, contentId }) => {
      logFetches && console.log(`removeItemFromList fired`);
      const response = await apiRequest(
        `${API_BASE_URL}/lists/${listId}/content/${contentId}`,
        {
          method: "DELETE",
        }
      );
      return response.data;
    },
    [apiRequest]
  );

  const replaceItemInList = useCallback(
    async ({ listId, oldContentId, newContentId }) => {
      logFetches && console.log(`replaceItemInList fired`);
      const response = await apiRequest(
        `${API_BASE_URL}/lists/${listId}/content/${oldContentId}`,
        {
          method: "PUT",
          data: { newContentId },
        }
      );
      return response.data;
    },
    [apiRequest]
  );

  const reorderItemsInList = useCallback(
    async ({ listId, items }) => {
      logFetches &&
        console.log(`reorderItemsInList fired for list ID: ${listId}`);
      const response = await apiRequest(`${API_BASE_URL}/lists/${listId}`, {
        method: "PUT",
        data: { items },
      });
      return response.data;
    },
    [apiRequest]
  );

  const editListDetails = useCallback(
    async ({ listId, title, description, isRanked, size }) => {
      logFetches && console.log(`editListDetails fired for list ID: ${listId}`);
      const response = await apiRequest(`${API_BASE_URL}/lists/${listId}`, {
        method: "PUT",
        data: { title, description, isRanked, size },
      });
      return response.data;
    },
    [apiRequest]
  );

  const createList = useCallback(
    async (newList) => {
      logFetches && console.log(`createList fired`);
      const response = await apiRequest(`${API_BASE_URL}/lists`, {
        method: "POST",
        data: newList,
      });
      return response.data;
    },
    [apiRequest]
  );

  const deleteList = useCallback(
    async (listId) => {
      logFetches && console.log("deleteList fired");
      const response = await apiRequest(`${API_BASE_URL}/lists/${listId}`, {
        method: "DELETE",
      });
      return response.data;
    },
    [apiRequest]
  );

  const fetchActivityFeed = useCallback(
    async ({ contentType, pageParam = 1, perPage = 10, self = false }) => {
      logFetches && console.log("fetchActivityFeed fired");
      const response = await apiRequest(`${API_BASE_URL}/feed`, {
        method: "GET",
        params: {
          contentType,
          page: pageParam,
          per_page: perPage,
          self: self,
        },
      });
      return {
        items: response.data.items,
        nextPage: response.data.has_more ? pageParam + 1 : undefined,
        hasMore: response.data.hasMore,
      };
    },
    [apiRequest]
  );

  const checkUsernameAvailability = useCallback(
    async (username) => {
      logFetches && console.log("getUserByUsername fired");
      const response = await apiRequest(`${API_BASE_URL}/check-username`, {
        method: "GET",
        params: {
          username: username,
        },
      });
      return response.data.isAvailable;
    },
    [apiRequest]
  );

  const fetchUserCounts = useCallback(async () => {
    logFetches && console.log("fetchUserCounts fired");
    const response = await apiRequest(`${API_BASE_URL}/user-counts`, {
      method: "GET",
    });
    return response.data;
  }, [apiRequest]);

  const editProfile = useCallback(
    async ({ username, bio, darkModePref, addButtonPref }) => {
      logFetches && console.log("editProfile fired");
      const response = await apiRequest(`${API_BASE_URL}/edit-profile`, {
        method: "PUT",
        data: {
          username,
          bio,
          darkModePref,
          addButtonPref,
        },
      });
      return response.data;
    },
    [apiRequest]
  );

  const authenticateUser = useCallback(async () => {
    logFetches && console.log("authenticateUser fired");
    try {
      const response = await apiRequest(`${API_BASE_URL}/token/auth`, {
        method: "GET",
      });
      const { userDetails } = response.data;
      setMode(userDetails.preferences.darkMode ? "dark" : "light");
      return response.data;
    } catch (error) {
      toast.error(
        "An error occurred while authenticating, please log in again"
      );
      return null;
    }
  }, [apiRequest, setMode]);

  const logoutUser = async () => {
    logFetches && console.log("logoutUser fired");
    const response = await apiRequest(`${API_BASE_URL}/logout`, {
      method: "POST",
    });
    return response.data;
  };

  const deleteUser = useCallback(
    async (logout) => {
      logFetches && console.log("deleteUser fired");
      try {
        const response = await apiRequest(`${API_BASE_URL}/delete-account`, {
          method: "POST",
        });
        logout();
        toast.success("Account deleted successfully");
        return response.data;
      } catch (error) {
        console.error("Error deleting user account:", error);
        toast.error(
          error.response?.data?.message || "Failed to delete user account"
        );
      }
    },
    [apiRequest]
  );

  const fetchUserSearch = useCallback(
    async (searchInput) => {
      logFetches &&
        console.log("fetchUserSearch fired with input: ", searchInput);
      const response = await apiRequest(
        `${API_BASE_URL}/user/search?q=${encodeURIComponent(searchInput)}`,
        {
          method: "GET",
        }
      );
      console.log("fetchUserSearch response: ", response);
      return response.data;
    },
    [apiRequest]
  );

  const fetchUserProfile = async (username) => {
    logFetches && console.log("fetchUserProfile fired");
    const response = await apiRequest(
      `${API_BASE_URL}/get-profile/${username}`,
      {
        method: "GET",
      }
    );
    return response.data;
  };

  const followUser = async (username) => {
    logFetches && console.log("followUser fired");
    const response = await apiRequest(`${API_BASE_URL}/follow/${username}`, {
      method: "POST",
    });
    return response.data;
  };

  const unfollowUser = async (username) => {
    logFetches && console.log("unfollowUser fired");
    const response = await apiRequest(`${API_BASE_URL}/unfollow/${username}`, {
      method: "DELETE",
    });
    return response.data;
  };

  return {
    fetchContentAutocomplete,
    fetchMovieMetaData,
    fetchBookMetaData,
    fetchMetadata,
    fetchContentSearch,
    fetchJournal,
    fetchStatuses,
    editEntry,
    addEntry,
    fetchJournalForMovie,
    fetchStatus,
    deleteEntry,
    fetchCollections,
    fetchList,
    editStatus,
    addItemToList,
    removeItemFromList,
    replaceItemInList,
    reorderItemsInList,
    deleteList,
    createList,
    editListDetails,
    addStatus,
    addContent,
    deleteStatus,
    fetchActivityFeed,
    fetchDefaultList,
    checkUsernameAvailability,
    fetchUserCounts,
    editProfile,
    authenticateUser,
    logoutUser,
    deleteUser,
    fetchUserSearch,
    fetchUserProfile,
    followUser,
    unfollowUser,
  };
}
