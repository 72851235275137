import React, { forwardRef, useState } from "react";
import SpeedDial from "@mui/material/SpeedDial";
import SpeedDialIcon from "@mui/material/SpeedDialIcon";
import SpeedDialAction from "@mui/material/SpeedDialAction";
import MovieRoundedIcon from "@mui/icons-material/MovieRounded";
import MenuBookRoundedIcon from "@mui/icons-material/MenuBookRounded";
import LiveTvRoundedIcon from "@mui/icons-material/LiveTvRounded";
import PodcastsRoundedIcon from "@mui/icons-material/PodcastsRounded";
import TheaterComedyRoundedIcon from "@mui/icons-material/TheaterComedyRounded";
import { useAuth } from "../context/authContext";
import { ReactComponent as AddIcon } from "../assets/IconAdd.svg";
import CloseIcon from "@mui/icons-material/Close";
import SvgIcon from "@mui/icons-material/MovieRounded";
import { motion } from "framer-motion";
import { Backdrop } from "@mui/material";
import { useThemeContext } from "../context/themeContext";
import { useTheme } from "@emotion/react";

const actions = [
  {
    icon: <MovieRoundedIcon />,
    label: "Add movie",
    disabled: false,
    value: "movie",
  },
  {
    icon: <LiveTvRoundedIcon />,
    label: "Add TV show",
    disabled: true,
    value: "tvshow",
  },
  {
    icon: <MenuBookRoundedIcon />,
    label: "Add book",
    disabled: true,
    value: "book",
  },
  {
    icon: <PodcastsRoundedIcon />,
    label: "Add podcast",
    disabled: true,
    value: "podcast",
  },
  {
    icon: <TheaterComedyRoundedIcon />,
    label: "Add theatre show",
    disabled: true,
    value: "theatre",
  },
];

export default function AddContentButton({ onSelect, isFabAnimated }) {
  const { user } = useAuth();
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const { isTouchDevice } = useThemeContext();
  const theme = useTheme();
  const fabColour = theme.palette.status[user.preferences.addButton].main;

  const MotionFabWrapper = forwardRef(function MotionFabWrapper(props, ref) {
    return (
      <motion.div
        animate={isFabAnimated ? { scale: [1, 1.1, 1] } : { scale: 1 }}
        transition={{
          duration: 2,
          repeat: Infinity,
        }}
        ref={ref}
        {...props}
      />
    );
  });

  return (
    <>
      <Backdrop
        open={open}
        sx={{
          zIndex: 1249,
          backgroundColor: (theme) =>
            theme.palette.mode === "dark"
              ? "rgba(0, 0, 0, 0.7)"
              : "rgba(0, 0, 0, 0.7)",
        }}
      />
      <SpeedDial
        ariaLabel="Add content to library"
        sx={{
          position: "fixed",
          bottom: 21,
          right: 21,
          zIndex: 1250,
        }}
        icon={
          <SpeedDialIcon
            icon={<SvgIcon component={AddIcon} inheritViewBox />}
            openIcon={<CloseIcon />}
          />
        }
        onClose={handleClose}
        onOpen={handleOpen}
        open={open}
        FabProps={{
          component: MotionFabWrapper,
          sx: {
            bgcolor: fabColour,
            "&:hover": {
              bgcolor: fabColour,
            },
          },
        }}
      >
        {actions.map((action) => (
          <SpeedDialAction
            key={action.label}
            icon={action.icon}
            tooltipTitle={action.label}
            disabled={action.disabled}
            tooltipOpen={!action.disabled && isTouchDevice}
            onClick={() => {
              handleClose();
              onSelect(action.value);
            }}
            FabProps={{
              sx: {
                bgcolor: fabColour,
                "&:hover": {
                  bgcolor: fabColour,
                },
              },
            }}
          />
        ))}
      </SpeedDial>
    </>
  );
}
