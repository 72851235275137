import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import ResponsiveDrawer from "../components/ResponsiveDrawer";
import { Outlet } from "react-router-dom";
// import { ToastContainer } from "react-toastify";
// import { useMediaQuery } from "@mui/material";
// import { useTheme } from "@emotion/react";

export default function Spa() {
  // const theme = useTheme();
  // const isBelowSm = useMediaQuery(theme.breakpoints.down("sm"));
  const [mobileOpen, setMobileOpen] = useState(false);
  const [isClosing, setIsClosing] = useState(false);
  const [isFabAnimated, setIsFabAnimated] = useState(false);
  const drawerWidth = 240;

  useEffect(() => {
    // Required to prevent context menu on Android (but not IOS)
    const isTouchDevice =
      window.matchMedia("(pointer: coarse)").matches ||
      navigator.maxTouchPoints > 0;

    if (!isTouchDevice) return; // only disable context menu on mobile
    const preventContextMenu = (event) => {
      if (event.target.closest(".allow-context-menu")) {
        return;
      }
      event.preventDefault();
    };
    document.addEventListener("contextmenu", preventContextMenu);
    return () => {
      document.removeEventListener("contextmenu", preventContextMenu);
    };
  }, []);

  const handleDrawerClose = () => {
    setIsClosing(true);
    setMobileOpen(false);
  };

  const handleDrawerTransitionEnd = () => {
    setIsClosing(false);
  };

  const handleDrawerToggle = () => {
    if (!isClosing) {
      setMobileOpen(!mobileOpen);
    }
  };

  return (
    <Box sx={{ display: "flex" }}>
      <ResponsiveDrawer
        handleDrawerToggle={handleDrawerToggle}
        mobileOpen={mobileOpen}
        handleDrawerTransitionEnd={handleDrawerTransitionEnd}
        handleDrawerClose={handleDrawerClose}
        drawerWidth={drawerWidth}
        isFabAnimated={isFabAnimated}
      />
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          height: "100dvh", // changed from 100vh for better mobile support
          // overflow: "auto", // can't apply this as prevents infinite scroll components
          // from triggering when scrolling to the bottom of the page, however maybe worth
          // investigating if this helps the library from scrolling behind the library modal error
        }}
      >
        <Outlet
          context={{
            toggleDrawer: handleDrawerToggle,
            drawerWidth,
            setIsFabAnimated,
          }}
        />
        {/* <ToastContainer
          position={isBelowSm ? "bottom-right" : "top-right"}
          autoClose={2000}
          hideProgressBar
          newestOnTop={true}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme={theme.palette.mode}
          style={{
            marginBottom: isBelowSm ? "80px" : undefined,
          }}
        /> */}
      </Box>
    </Box>
  );
}
