import { Button, Link, ToggleButton, ToggleButtonGroup } from "@mui/material";
import { styled, alpha } from "@mui/material/styles";
import InputBase from "@mui/material/InputBase";

export const HeaderButton = styled(Button, {
  shouldForwardProp: (prop) => prop !== "mobile",
})(({ theme, variant, mobile }) => ({
  height: "38px",
  boxShadow: "none", // Default to no box shadow

  // Conditional styles based on the variant
  ...(variant === "contained" && {
    backgroundColor: theme.palette.secondary.main,
    color: theme.palette.secondary.contrastText,
    "&:hover": {
      backgroundColor: theme.palette.secondary.main,
      boxShadow: "0px 2px 4px rgba(0,0,0,0.2)",
    },
  }),
  ...(variant === "outlined" && {
    color: theme.palette.primary.main,
    border: `1px solid ${theme.palette.primary.main}`,
    "&:hover": {
      backgroundColor: theme.palette.action.hover,
    },
  }),
  ...(mobile && {
    minWidth: 0,
    padding: 0,
    height: 38,
    width: 38,
    ".MuiButton-startIcon": {
      margin: 0,
    },
  }),
}));

HeaderButton.defaultProps = {
  variant: "contained",
};

export const HeaderToggleButtonGroup = styled(ToggleButtonGroup)(
  ({ theme }) => ({
    "& .MuiToggleButton-root": {
      border: "none",
      borderRadius: theme.shape.borderRadius,
    },
  })
);

export const HeaderToggleButton = styled(ToggleButton)(({ theme }) => ({
  "&.Mui-selected": {
    backgroundColor: theme.palette.secondary.main,
    "&:hover": {
      backgroundColor: theme.palette.secondary.main,
    },
  },
  "&:hover": {
    backgroundColor: alpha(theme.palette.secondary.main, 0.3),
  },
  // "& .MuiTouchRipple-child": {
  //   backgroundColor: theme.palette.secondary.main, // Custom ripple color
  // },
}));

export const Search = styled("div")(({ theme, highlight }) => ({
  position: "relative",
  borderRadius: theme.shape.borderRadius,
  backgroundColor: theme.palette.secondary.main,
  marginRight: theme.spacing(2),
  width: "auto",
  height: "38px",
  display: "flex",
  border: highlight ? `1px solid ${theme.palette.primary.main}` : undefined, // Conditional border
}));

export const SearchIconWrapper = styled("div")(({ theme, highlight }) => ({
  paddingLeft: theme.spacing(1),
  height: "100%",
  position: "absolute",
  pointerEvents: "none",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  color: highlight ? theme.palette.primary.main : undefined, // Conditional icon color
}));

export const StyledInputBase = styled(InputBase)(({ theme }) => ({
  "& .MuiInputBase-input": {
    paddingLeft: "38px",
    transition: theme.transitions.create("width"),
    fontSize: "0.875rem",
    width: 0,
    "&:focus, &:not(:placeholder-shown)": {
      width: "10ch", // Default width on focus for smaller screens
      // pr: "24px",
      [theme.breakpoints.up("md")]: {
        width: "15ch", // Larger width on focus for wider screens
      },
    },
  },
}));

export const StyledLink = styled(Link)(({ theme }) => ({
  color: "inherit",
  textDecoration: "none",
  cursor: "pointer",
  "&:hover": {
    color: "#00d37e",
    textDecoration: "underline",
  },
}));
