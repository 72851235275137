import React, { useState, useEffect } from "react";
import { useInfiniteQuery } from "@tanstack/react-query";
import {
  Box,
  Container,
  Dialog,
  DialogContent,
  Grid,
  LinearProgress,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import CollectionsHeader from "../features/curate/Header.js";
import useApi from "../hooks/useApi.js";
import CollectionCover from "../features/curate/Cover.js";
import Collection from "../features/curate/Collection.js";
import InfiniteScroll from "react-infinite-scroll-component";
import Loading from "../components/Loading.js";
import { useOutletContext } from "react-router-dom";
import EmptyCollection from "../features/curate/EmptyCollection.js";
import ErrorPage from "./ErrorPage.js";

export default function Curate() {
  const { toggleDrawer, drawerWidth } = useOutletContext();
  const theme = useTheme();
  const { fetchCollections } = useApi();
  const [view, setView] = useState("grid");
  const [contentType, setContentType] = useState("all");
  const [sortBy, setSortBy] = useState("updated");
  const [sortDescending, setSortDescending] = useState(true);
  const [searchTerm, setSearchTerm] = useState("");
  const [newCollectionId, setNewCollectionId] = useState(null);
  const [activeCollectionId, setActiveCollectionId] = useState(null);
  const fullscreen = useMediaQuery(theme.breakpoints.down("sm"));

  const handleViewChange = (event, newView) => {
    if (newView !== null) {
      setView(newView);
    }
  };

  const handleContentChange = (newValue) => {
    setContentType(newValue);
  };

  const handleSortByChange = (event, newSortBy) => {
    if (newSortBy !== null) {
      setSortBy(newSortBy);
    }
  };

  const toggleSortDescending = () => {
    setSortDescending(!sortDescending);
  };

  const handleSearchTermChange = (newValue) => {
    setSearchTerm(newValue);
  };

  const collectionsInfiniteQuery = useInfiniteQuery({
    queryKey: [
      "infCollections",
      contentType,
      { sortBy, sortDescending, searchTerm },
    ],
    queryFn: ({ pageParam = 1 }) =>
      fetchCollections({
        pageParam,
        isDefault: false,
        perPage: 5,
        sortDescending,
        sortBy,
        searchTerm,
        contentType,
      }),
    getNextPageParam: (lastPage) => lastPage.nextPage,
    staleTime: 24 * 60 * 60 * 1000, // 24 hours
    cacheTime: 7 * 24 * 60 * 60 * 1000, // 1 week
  });

  const collectionsData = React.useMemo(
    () =>
      collectionsInfiniteQuery.data?.pages.flatMap((page) => page.items) || [],
    [collectionsInfiniteQuery.data]
  );

  const activeCollection = collectionsData
    ? collectionsData.find(
        (collection) => collection.list_id === activeCollectionId
      )
    : null;

  useEffect(() => {
    if (
      newCollectionId &&
      collectionsData.some((list) => list.list_id === newCollectionId)
    ) {
      setActiveCollectionId(newCollectionId);
      setNewCollectionId(null);
    }
  }, [collectionsData, newCollectionId]);

  if (collectionsInfiniteQuery.isError) return <ErrorPage />;

  return (
    <Container maxWidth="lg" sx={{ pb: 2 }}>
      <CollectionsHeader
        contentType={contentType}
        onContentChange={handleContentChange}
        view={view}
        onViewChange={handleViewChange}
        sortBy={sortBy}
        onSortByChange={handleSortByChange}
        sortDescending={sortDescending}
        toggleSortDescending={toggleSortDescending}
        searchTerm={searchTerm}
        onSearchTermChange={handleSearchTermChange}
        toggleDrawer={toggleDrawer}
        setNewCollectionId={setNewCollectionId}
        drawerWidth={drawerWidth}
      />

      {collectionsInfiniteQuery.isPending && (
        <Box
          width="100%"
          height="75vh"
          display="flex"
          justifyContent="center"
          alignItems="center"
        >
          <Loading />
        </Box>
      )}

      {!collectionsInfiniteQuery.isPending &&
      !collectionsInfiniteQuery.isError &&
      collectionsData.length === 0 ? (
        <Grid
          item
          xs={12}
          sx={{
            display: "flex",
            justifyContent: "center",
          }}
        >
          <EmptyCollection filtersApplied={searchTerm !== ""} />
        </Grid>
      ) : (
        <>
          <InfiniteScroll
            dataLength={collectionsData.length}
            next={collectionsInfiniteQuery.fetchNextPage}
            hasMore={!!collectionsInfiniteQuery.hasNextPage}
            loader={
              <Box sx={{ pt: 2, pb: 2 }}>
                <LinearProgress />
              </Box>
            }
            style={{ overflow: "visible" }}
            // ^ Required to ensure framer-motion scale animation applied to library cards displays properly
          >
            <Grid container sx={{ pt: 1, pb: 2 }} spacing={3}>
              {collectionsData.map((list, index) => (
                <Grid item xs={12} key={list.list_id}>
                  <CollectionCover
                    list={list}
                    reverse={index % 2 === 0}
                    onClick={() => setActiveCollectionId(list.list_id)}
                    isMobile={fullscreen}
                  />
                </Grid>
              ))}
            </Grid>
          </InfiniteScroll>

          {activeCollectionId && (
            <Dialog
              open={!!activeCollectionId}
              fullScreen={fullscreen}
              onClose={() => setActiveCollectionId(null)}
              fullWidth
              PaperProps={{
                sx: {
                  maxWidth: { md: "80%" },
                  width: "auto",
                  backgroundImage: "none",
                },
              }}
            >
              <DialogContent sx={{ p: 1.5 }}>
                <Collection
                  list={activeCollection}
                  onDelete={() => setActiveCollectionId(null)}
                  onClose={() => setActiveCollectionId(null)}
                  fullScreen={fullscreen}
                />
              </DialogContent>
            </Dialog>
          )}
        </>
      )}
    </Container>
  );
}
