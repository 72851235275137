import React, { useRef } from "react";
import {
  Card,
  CardActionArea,
  CardMedia,
  Skeleton,
  IconButton,
  Stack,
  Tooltip,
  Typography,
  alpha,
} from "@mui/material";
import { useContentMetadata } from "../hooks/useCustomQuery";
import { useThemeContext } from "../context/themeContext";

function PosterTile({
  contentId,
  onClick,
  secondaryActions = [],
  position = undefined,
  padding,
  zIndex,
  skeleton = false,
}) {
  const { data: metadata, isLoading } = useContentMetadata("movie", contentId);
  const { isTouchDevice } = useThemeContext();
  const [showOptions, setShowOptions] = React.useState(false);
  const timerRef = useRef(null); // timer for long press

  const handleTouchStart = (event) => {
    timerRef.current = setTimeout(() => {
      if (onClick) {
        setShowOptions(false);
        onClick();
      }
    }, 500); // 500ms long-press threshold
  };

  const handleTouchEnd = () => {
    clearTimeout(timerRef.current);
  };

  const handleTouchMove = () => {
    clearTimeout(timerRef.current);
    setShowOptions(false);
  };

  const onTap = (event) => {
    if (secondaryActions.length > 0) {
      setShowOptions(!showOptions);
    } else if (onClick) {
      // default to onClick behaviour if no secondary actions provided
      onClick();
    }
  };

  const cardContent = (
    <>
      {isLoading || skeleton ? (
        <CardMedia
          component={Skeleton}
          variant="rectangular"
          width="100%"
          animation={skeleton ? false : "wave"}
          sx={{
            display: "block",
            paddingTop: "150%",
            height: 0,
            borderRadius: 1,
          }}
        />
      ) : (
        metadata && (
          <CardMedia
            component="img"
            sx={{
              width: "100%",
              objectFit: "contain",
              borderRadius: 1,
              filter: position ? "brightness(60%)" : "none",
            }}
            image={`https://image.tmdb.org/t/p/w185/${metadata.image_url}`}
            loading="lazy"
            alt={`${metadata.title} poster`}
          />
        )
      )}
      {position !== null && (
        <Typography
          sx={{
            position: "absolute",
            top: 7,
            left: 10,
            color: "white",
            fontSize: { xs: "3rem", sm: "4rem" },
            lineHeight: 1,
            fontWeight: "bold",
            zIndex: 1, // Ensure it's above the image
          }}
        >
          {position}
        </Typography>
      )}
    </>
  );

  return (
    <Card
      onClick={isTouchDevice ? onTap : onClick}
      onTouchStart={handleTouchStart}
      onTouchEnd={handleTouchEnd}
      onTouchMove={handleTouchMove}
      {...(secondaryActions.length > 0 && {
        onMouseEnter: () => setShowOptions(true),
        onMouseLeave: () => setShowOptions(false),
      })}
      sx={{
        maxWidth: 300,
        position: "relative",
        width: "100%",
        height: "100%",
        padding: padding || undefined,
        boxShadow: padding ? "none" : "default",
        // backgroundColor: (theme) =>
        //   padding ? theme.palette.grey[100] : "inherit",
        zIndex: zIndex || undefined,
      }}
    >
      {onClick ? <CardActionArea>{cardContent}</CardActionArea> : cardContent}
      {secondaryActions.length > 0 && showOptions && (
        <Stack
          direction="column"
          spacing={1}
          sx={{
            position: "absolute",
            top: 8,
            right: 8,
            // padding: "4px",
          }}
        >
          {secondaryActions.map((option) => (
            <Tooltip
              placement="left"
              title={option.label}
              key={option.value}
              // {...(isTouchDevice && { open: isTouchDevice })} // uncomment to show tooltip label by default on touch devices
            >
              <IconButton
                onClick={option.onClick}
                aria-label={option.label}
                size="small"
                sx={{
                  background: (theme) => alpha(theme.palette.primary.main, 0.5), // Light transparent version
                  transition: "background 0.2s ease",
                  "&:hover": {
                    background: (theme) => alpha(theme.palette.primary.main, 1), // Less transparent on hover
                  },
                }}
              >
                {option.icon}
              </IconButton>
            </Tooltip>
          ))}
        </Stack>
      )}
    </Card>
  );
}

export default PosterTile;
