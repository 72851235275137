import { useRef, useCallback, useEffect } from "react";

/**
 * Custom hook for debouncing a function in React with cancel capability.
 * @param {Function} func - The function to debounce
 * @param {number} delay - Delay in milliseconds
 */
export const useDebounce = (func, delay) => {
  const timerRef = useRef();
  const latestFunc = useRef(func);
  const latestArgs = useRef();

  useEffect(() => {
    latestFunc.current = func;
  }, [func]);

  // The debounced function
  const debouncedFunction = useCallback(
    (...args) => {
      clearTimeout(timerRef.current);
      latestArgs.current = args;
      timerRef.current = setTimeout(() => {
        latestFunc.current(...args);
      }, delay);
    },
    [delay]
  );

  debouncedFunction.flush = async () => {
    if (timerRef.current && latestArgs.current) {
      clearTimeout(timerRef.current);
      await latestFunc.current(...latestArgs.current);
      latestArgs.current = null;
    }
    return Promise.resolve();
  };

  // Add a cancel method to clear the timeout
  debouncedFunction.cancel = () => {
    clearTimeout(timerRef.current);
  };

  return debouncedFunction;
};
